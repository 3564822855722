import {
    find,
    last,
    pipe,
    propEq,
    test,
    insert,
    map,
    set,
    is,
    has,
    propOr,
    pluck,
    allPass,
    curry,
    pathOr,
    memoizeWith,
    ifElse,
    gt,
    __,
    T,
    F,
    complement,
    over,
    lensProp,
    lensPath,
    update,
    identity,
    assoc,
    append, reverse, isEmpty
} from "ramda";
import {isNotNil} from "./utils";
import {getHistoryForSite, rejectActionsByType} from "./modelHelper";
import {ROLLBACK_SETTINGS} from "../components/stores/sites/types";
import {selectSettingsFromAction} from "./actions";
import {undoAction} from "../components/stores/sites/sitesactions";

const resolveMainDomain = (domain) => {
    const matchArr = domain.match(/([a-zA-Z-]+\.[a-zA-Z]+)$/);
    return matchArr ? matchArr[0] : domain;
}

const siteSort = (a, b) => {

    // Place global in the very top
    if(a.isGlobal === 'true'){ 
        return -1;
    }
    else if(b.isGlobal === 'true'){ 
        return 1;
    }
    // Sort main domains
    if(a.domain > b.domain){
        return 1;
    }
    else if(a.domain < b.domain){
        return -1;
    }
    else{
        // Place main domain in top of subdomains
        if(a.domain === a.gamSiteId){ 
            return -1;
        }else if(b.domain === b.gamSiteId){
            return 1;
        }
        // Sort subdomains
        if(a.gamSiteId > b.gamSiteId){
            return 1;
        }
        else if(a.gamSiteId < b.gamSiteId){
            return -1;
        }
        return 0;
    }
}

export const transformSites = payload => {
    const sitesRaw = pipe(propOr([], 'data'))(payload);
    
    const sitesProcessed = sitesRaw
    .map((site) => {
        site.domain = resolveMainDomain(site.gamSiteId);
        return site;
    })
    .sort(siteSort)
    .map((site) => {
        delete site['domain'];
        return site;
    })
    return sitesProcessed;
}

// @TODO: Fix this råttbo
export const getAllUpdatesFor = (name) => state => pipe(
    getHistoryForSite(name),
    rejectActionsByType([ROLLBACK_SETTINGS]),
    pluck('payload'),
    pluck('settings'),
    insert(0, selectSettingForSite(name)( state))
)(state)


/**
 * Retrieve the active settings for a site from either the update stack or site state.
 * @param siteName
 * @param state {object} Site settings state
 * @param ignoredActionTypes {string[]} List with the type of history object to ignore
 * @returns {object}
 */
export const getActiveSettings = (siteName, state, ignoredActionTypes = [ROLLBACK_SETTINGS]) => {
    const lastUpdateAction = pipe(
        getHistoryForSite(siteName),
        rejectActionsByType(ignoredActionTypes),
        last,
        selectSettingsFromAction
    )(state);

    return lastUpdateAction ? lastUpdateAction : selectSettingForSite(siteName)(state);
}

export const isValidAction = allPass([isNotNil, is(Object), has('type'), has('payload')]);

export const getIdFromPosition = (siteName, position) => {
    const curriedGetActive = curry(getActiveSettings);

    return pipe(
        curriedGetActive(siteName),
        propOr([], 'slots'),
        pathOr({}, [position]),
        propOr(undefined, 'slotPlacementId')
    )
};

const selectSites = pipe(propOr([], 'sites'));

export const getNames = pipe(map(propOr('unknown', 'name')));

const selectSettingForSite = siteName => pipe(selectSites, find(propEq('name', siteName)));  // @TODO: Whats going on here? (look at impl from modelHelper)

export const createProperty = (targetProp, data, all) => ifElse(
    has(targetProp), identity, assoc(targetProp, []))(all)

export const appendItemToProp = (prop, item, source) => over(lensProp(prop), append(item))(source);


const unsavedKey = (...args) => `${JSON.stringify(args[1].updates)}${JSON.stringify(args[2])}`;

export const siteHasUnsavedChanges = memoizeWith(unsavedKey,
    (siteName, state, ignoredActions = [ROLLBACK_SETTINGS]) =>
    pipe(
        getHistoryForSite(siteName),
        rejectActionsByType(ignoredActions),
        propOr(0, 'length'),
        ifElse(gt(__, 0), T, F))(state)
);

export const siteHasNotUnsavedChanges =  complement(siteHasUnsavedChanges);

const urlRegexLiteral = /^[(http(s)?)://(www.)?\w\-/=#%&.?]{2,}\.[a-z]{2,}([\w\-/=#%&.?]*)$/;

const urlPattern = urlStr => urlRegexLiteral.test(urlStr);

const matchUrlPattern = content => {
    if(!isEmpty(content)){
        const val = urlPattern(content);
        return !val;
    }
    return false;
}

export const hasTextFieldError = (unsavedKey,(siteName, state, ignoredActions = [ROLLBACK_SETTINGS]) =>
    pipe(
        getHistoryForSite(siteName),
        rejectActionsByType(ignoredActions),
        reverse,
        pathOr(undefined, [[0],'payload', 'settings', 'demandManagerUrl']),
        matchUrlPattern,
        )(state)    
);

const applyUpdate = index => index > -1 ? curry(update)(index) : append;

export const updateItemAtIndex = (focusProp, index, item, source) => over(lensProp(focusProp), applyUpdate(index)(item) )(source);

export const appendItemToPropCurried = curry(appendItemToProp);


/**
 * Update the full history state with updated settings for a specific site
 *
 * @param {string} sitename
 * @param {object} settings - Updated settings for a site
 * @param {object} updateStack - The complete history stack for all sites
 * @return {object} updated history for all available sites
 */
export const updateSiteHistory = (siteName, settings, updateStack) => pipe(
    createProperty,
    appendItemToPropCurried(siteName, undoAction(siteName, settings))
)(siteName, settings, updateStack);

export const updateProp = (pathProp, updatedItem, source) => set( lensPath(pathProp.split('.')), updatedItem, source);

export const updateSlotAtIndex = curry(updateItemAtIndex)('slots');

export const updateStateHistory = curry(updateProp)('updates');

export const isGlobalSite = pipe(
    ifElse(
        has('isGlobal'),
        propEq('isGlobal', 'true'),
        F)
);

export const isSubSite = pipe( propOr(0, 'parentSiteId'), gt(__, 0) );

export const isSubdomain = pipe(propOr('', 'gamSiteId'), test(/[A-Za-z0-9]*\.[A-Za-z0-9]*\.[A-Za-z0-9]*/));

const ocelotSites = ['allas', 'hant', 'elle', 'femina', 'mabra', 'motherhood','svenskdam','residence'];

export const isOcelotSite = (siteName) => ocelotSites.includes(siteName.split('.')[0]);

export const isRemovedSize = size => !size?.adSlotSize?.startsWith("r");

/**
 * @function getGlobalAndParentSlotAndSize() Get Global and Parent size of a slot placement
 * @param {Array} sites Array of all site data
 * @param {string} currentSlotPlacementId Placement ID of the slot
 * @param {string} currentGamSiteId `gamSiteId` of the site that the slot is part of
 * @returns {{ globalSlot: object, parentSlot: object, globalSizes: object[], parentSizes: object[], commonSizes: object[] }}
 */
export function getGlobalAndParentSlotAndSize(sites, currentSlotPlacementId, currentGamSiteId) {
    const findByGamSiteId = site => site?.gamSiteId !== currentGamSiteId && !!currentGamSiteId?.endsWith(site.gamSiteId);
    const findBySlotPlacementId = slot => slot.slotPlacementId === currentSlotPlacementId;
    const parentSlot = sites.find(findByGamSiteId)?.slots?.find(findBySlotPlacementId);
    const parentSizes = (parentSlot?.sizes || []).filter(isRemovedSize);
    const globalSlot = sites.find(isGlobalSite).slots.find(findBySlotPlacementId);
    const globalSizes = (globalSlot?.sizes || []).filter(isRemovedSize);

    const commonSizes = [
        ...globalSizes,
        ...(parentSizes.filter(
            pSize => !globalSizes.some(gSize => gSize.adSlotSize === pSize.adSlotSize)
        )),
    ];

    return { globalSlot, parentSlot, globalSizes, parentSizes, commonSizes };
}