import React, {useState} from 'react';
import ToastContext from "../stores/toast/toastcontext";
import Toast from 'react-bootstrap/Toast';
import styles from "./allertoast.module.scss";

const AllerToast = (props) => {

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [validation, setValidation] = useState(true);

    const display = (desc, isNotValid) => {
        setMessage(desc);
        setShow(true);
        setValidation(!isNotValid)       
    };

    return (
        <ToastContext.Provider value={{ display }}>
            <div className={`  ${!validation ? styles.aller_toast_error : styles.aller_toast_success} ${styles.aller_toast}`}>
                <Toast onClose={() => {setShow(false);setValidation(true);}} show={show} delay={5000} autohide>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </div>
            {props.children}
        </ToastContext.Provider>
    )
}

export default AllerToast;