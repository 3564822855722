import React, {useContext, useState} from "react";
import SiteTabs from "../siteTabs/siteTabs";
import sitesContext from "../../stores/sites/sitescontext";

const SiteList = () => {
    const SitesContext = useContext(sitesContext);
    const [opened, setOpen] = useState();
    return (
        <>
            <div>
            {SitesContext.sites.length
                ? SitesContext.sites.map((site, i) => (
                    <SiteTabs
                        key={i}
                        site={site}
                        opened={opened} 
                        setOpen={setOpen}                    
                    >
                    </SiteTabs>
                ))
                : null}
            </div>
        </>
    )
}

export default SiteList
