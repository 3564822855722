import React, {useReducer, useEffect, useMemo} from "react";
import SizesReducer from "./sizesreducer";
import SizesContext from "./sizescontext"
import {GET_SIZES} from "./types";
import instance from "../../../axios";
import {groupBy, keys, map, objOf, pipe, prop} from "ramda";
import {numberSort} from "../../../helpers/utils";

const Sizes = (props) => {

    let initialState = {sizes: []};
    const [state, dispatch] = useReducer(SizesReducer, initialState);

    useEffect(() => {
        fetchSizes();
    }, []);

    const fetchSizes = async () => {
        try {
            let res = await instance({url: 'sizes', withCredentials: true});
            let { data } = res;
            dispatch({ type: GET_SIZES, payload: data });
        } catch (error) {
            console.error(error);
        }
    }

    const uniqueSizes = useMemo(() => pipe(
            groupBy(prop('adSlotSize')),
            keys,
            map( objOf('adSlotSize')),
            numberSort
        )(state.sizes), [state]);
    
    return (
        <SizesContext.Provider value={{sizes: state.sizes, uniqueSizes, fetchSizes}}>
            {props.children}
        </SizesContext.Provider>
    )
};

export default Sizes;
