import React, {useState, useRef, useContext, useMemo, useCallback} from 'react';
import styles from "./adslot.module.scss";
import {deepCopy, isNotEmptyObject} from "../../helpers/utils";
import {uniqueLocalSizes} from "../../helpers/sizes";
import {
    lensProp,
    set,
    uniq,
    isEmpty
} from 'ramda';
import placementContext from "../stores/placement/placementscontext";
import sitesContext from "../stores/sites/sitescontext";
import sizeContext from "../stores/sizes/sizescontext";
import DeleteButton from "../ui/deletebutton/deletebutton";
import AdFormat from "./adformat";
import {isGlobalPlacement} from "../../helpers/placements";
import SizeSelector from "../sizeSelector";
import { getGlobalAndParentSlotAndSize } from "../../helpers/sites";
import { AuthBasedRender } from "../stores/auth/authbasedrender";
import { AuthRoles } from "../stores/auth/authRole";
import AuthContext from "../stores/auth/authcontext";

const resolveSlotPlacementId = data => data && typeof data.slotPlacementId !== 'undefined' ? `${data.slotPlacementId}` : '';

const mapGlobalSelectionWithValueTrue = size => ({
    size,
    isGlobal: true,
});

const mapGlobalSelectionWithValueFalse = size => ({
    size,
    isGlobal: false,
});

const updateProperty = (prop, value, data) => {
    const lens = lensProp(prop);
    const updated = set(lens, value, data);
    return updated;
}

const Action = {
    Delete: 'delete',
    Update: 'update'
}

const AdSlot = (props) => {
    const {settings, currentSite, onUpdate, onDeleteSlot, localPlacements, globalParent: highlightGlobal} = props;
    const PlacementsContext = useContext(placementContext);
    const SizeContext = useContext(sizeContext);
    const SitesContext = useContext(sitesContext);

    const authContext = useContext(AuthContext)

    const [slotData, setSlotData] = useState({...settings})


    let sizeInputRef = useRef();

    const isGlobal = useMemo( () => highlightGlobal && isGlobalPlacement(slotData.slotPlacementId)(SitesContext.state), [SitesContext.state, highlightGlobal, slotData]);

    const onChange = useCallback( label => value =>  {

        const updatedPlacement = modifyInheritedPlacement(slotData, Action.Update);
        const updated = updateProperty(label, value, updatedPlacement);
        PlacementsContext.createPlacement(updated?.slotPlacementId);
        onUpdate(updated);
    }, [slotData, PlacementsContext, onUpdate]);

    const modifyInheritedPlacement = (slotData, action) => {
      if (
        slotData?.isInherited ||
        slotData?.isParentInherited ||
        slotData?.isCommonSlot
      ) {
        let placementObj = {
          slotPlacementId: slotData.slotPlacementId,
          siteId: slotData.siteId,
          sizes: slotData.sizes,
        };
        if (action === Action.Update) {
          placementObj.isEnable = 1;
          return placementObj;
        } else if (action === Action.Delete) {
          placementObj.isEnable = 0;
          placementObj.isNested = true;
          return placementObj;
        }

        }else if(isEmpty(slotData)){ // to add deleted placement in the list
            const placementObj = {
            isEnable: 1,
            sizes: []
            }
            return placementObj;
        }
      return slotData;
    };

    const onDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        const modifyPlacement = modifyInheritedPlacement(slotData, Action.Delete);
        onDeleteSlot(modifyPlacement);
    }, [slotData, onDeleteSlot]);

    const {
        commonSizes,
    } = React.useMemo(() => 
        getGlobalAndParentSlotAndSize(SitesContext.state.sites, slotData.slotPlacementId, currentSite?.gamSiteId),
        [SitesContext, slotData, currentSite]
    );

    const sizeOptions = useMemo(() => (unique, local) => {
        const options = uniqueLocalSizes(unique, local);
        return options.filter(
            opt => !commonSizes.some(sz => sz?.adSlotSize === opt)
        );
    }, [commonSizes]);


    
    const selectedSizes = useMemo(() => {
        if (currentSite?.isGlobal === "true") {
            return (slotData.sizes || []).map(mapGlobalSelectionWithValueFalse);
        }
        let selection = (slotData.sizes || []).map(mapGlobalSelectionWithValueFalse);
        const copiedSelection = deepCopy(selection);
     
        const deletedOptions = (slotData.sizes || []).filter(
            sz => typeof sz?.adSlotSize === "string" && sz.adSlotSize.startsWith("r")
        ).map(sz => sz.adSlotSize.slice(1));
         
        const commonSizesWithoutDelete = commonSizes.filter(csz => !deletedOptions.includes(csz?.adSlotSize));
        const copyCommonWithoutDelete = deepCopy(commonSizesWithoutDelete);

        if (!isEmpty(commonSizesWithoutDelete) || !isEmpty(deletedOptions)) {
            if (!slotData.hasOwnProperty('isInherited') || !slotData.hasOwnProperty('isParentInherited')) {
                slotData.isCommonSlot = true;
                setSlotData(slotData);
            }
        }

        !isEmpty(copiedSelection) && !isEmpty(copyCommonWithoutDelete) && copiedSelection.forEach((selectionItem,idx) => {
            copyCommonWithoutDelete.forEach((commonItem) => {
                if (selectionItem.size?.adSlotSize === commonItem?.adSlotSize) {
                    let newSelection = selection.filter((item) => item.size?.adSlotSize !== selectionItem.size?.adSlotSize) 
                    selection = newSelection;
                }
            })
        })
        const val = selection.concat(commonSizesWithoutDelete.map(mapGlobalSelectionWithValueTrue));
        return val;
    }, [commonSizes, slotData, currentSite]);
    // -- Hooks

    // -- Render

    return(
        <div className={`${styles.adslot} ${styles["adslot_sizes--open"]}` }>

            <div className={`${styles.slot_option} ${styles.adslot_format}`}>
                <AdFormat
                    onChange={onChange('slotPlacementId')}
                    isGlobal={isGlobal}
                    options={uniq(localPlacements)}
                    selected={resolveSlotPlacementId(slotData)}
                />
            </div>

            { isNotEmptyObject(settings) &&
                <>
                    <div ref={sizeInputRef} className={`${styles.slot_option} ${styles.adslot_sizes}`}>
                        <SizeSelector selected={selectedSizes}
                               options={sizeOptions(SizeContext.uniqueSizes, slotData.sizes)}
                               isEditable={ authContext.role >= AuthRoles.Admin }
                               onChange={onChange('sizes')} />
                    </div>

                    <AuthBasedRender role={AuthRoles.Admin}>
                        <div className={`${styles.slot_option} ${styles.adslot_toolbar} `}>
                            <DeleteButton onClick={onDelete}/>
                        </div>                    
                    </AuthBasedRender>
                </>
               }
        </div>
    )
}

export default React.memo(AdSlot);
