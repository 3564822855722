import * as React from "react";

function SvgExclamation(props) {
        return (
<svg width={41} height={43} xmlns="http://www.w3.org/2000/svg" {...props}>
<g fill="none" fillRule="evenodd">
<circle fill="#BA1C2F" cx={20.5} cy={22.5} r={20.5} />
<text
        fontFamily="HelveticaNeue-Bold, Helvetica Neue"
        fontSize={36}
fontWeight="bold"
fill="#FFF"
>
<tspan x={16} y={35}>
{"!"}
</tspan>
        </text>
        </g>
        </svg>
        );
        }

export default SvgExclamation;