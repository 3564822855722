import * as React from "react"

function SvgGarbage(props) {
    return (
        <svg
            width={41}
            height={41}
            viewBox="0 0 41 41"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >

            <path d="M26.836 17.472H14.164c-.387 0-.696.297-.657.668l1.468 13.132c.039.408.425.705.889.705h9.272c.464 0 .812-.297.889-.705l1.468-13.132c.039-.371-.27-.668-.657-.668zM16.714 29.973c0 .26-.194.446-.464.446s-.464-.186-.464-.446v-9.756c0-.26.194-.445.464-.445s.464.185.464.445v9.756zm2.82 0c0 .26-.193.446-.464.446-.27 0-.463-.186-.463-.446v-9.756c0-.26.193-.445.463-.445s.464.185.464.445v9.756zm2.86 0c0 .26-.194.446-.464.446s-.464-.186-.464-.446v-9.756c0-.26.193-.445.464-.445.27 0 .463.185.463.445v9.756zm2.82 0c0 .26-.194.446-.464.446s-.464-.186-.464-.446v-9.756c0-.26.194-.445.464-.445s.464.185.464.445v9.756zM23.861 9.31H17.14c-.657 0-1.237.52-1.237 1.188v2.41h1.816v-1.854h5.602v1.855h1.816v-2.411a1.256 1.256 0 00-1.275-1.188zM27.764 13.391H13.236c-.502 0-.888.297-.888.63v2.115c0 .334.386.63.888.63h14.528c.502 0 .888-.296.888-.63v-2.114c0-.334-.386-.63-.888-.63z" />



        </svg>



    )
}

export default SvgGarbage;


