import { SettingsHeadline } from "../containers/dynamicSettings/dynamicSettings";
import getEnvParam, {EnvParams} from "../../environment";

const controls = {
    submit: {
        label: 'Save'
    }
}

const rowStructure = [
    {
        prop: "adsBundleVersion",
        label: {
            desc: 'Ads Bundle Version ',
            size: 'auto'
        },
        value: "",
        size: 12
    }
]
const bucket = getEnvParam(EnvParams.CDN_DFP_URL);

async function getTextFromUrlIfValid(url) {
    try {
        const response = await fetch(url);
        let txt
        if(typeof response.text === 'function') {
            txt = await response.text()
        }
        return response.ok && txt;
  
    } catch(error) {
        console.warn(error);
        return false;
    }
}

const resolveVersion = propArr => Array.isArray(propArr) && propArr[0] && propArr[0]['adsBundleVersion']

const validCdnBundleExists = async (props) => {
    let isValid = false

    const bundleVersion = resolveVersion(props)

    if (bundleVersion === '') return true;

    if(bundleVersion && bucket !== '') {
        const cdnUrl = `${bucket}/${bundleVersion}/aller-ads.bundle.min.js`;
        const urlResponseText = await getTextFromUrlIfValid(cdnUrl); 
        isValid = urlResponseText && urlResponseText?.includes(bundleVersion);
    }
    return isValid
}
const settings = {
    headlineType: SettingsHeadline.LEADING,
    endpoint: 'adsBundleVersions',
    inputValue : 'adsBundleVersion',
    description: `Here below you enter unique Ads Bundle Versions, that exists on CDN ${bucket}`,
    toastMessage: {
        success: props => `Bundle ${resolveVersion(props) || ''} has been saved`,
        error: props => `Bundle ${resolveVersion(props) || ''} could Not be verified on CDN (${bucket})`
    },
    inputs: 'single',
    prepRequest: (rows, additional) => rows.map(row => ({ ...row, ...additional })),
    validateFunc: validCdnBundleExists
}

export const ADS_BUNDLE_VERSION = {
    controls,
    rowStructure,
    settings
}