import React, {useEffect, useState, useContext} from 'react';
import styles from './userprofile.module.scss';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import authContext from '../stores/auth/authcontext';
import toastContext from '../stores/toast/toastcontext';
import { SvgUser } from "../svg/svguser";
import {useIsLoggedIn} from "../../hooks/useloginstate";


const UserProfile = () => {

    const AuthContext = useContext(authContext)
    const ToastContext = useContext(toastContext)

    const {loggedIn} = useIsLoggedIn();

    const [userName, setUserName] = useState('');
    

    useEffect(() => {

        setUserName(AuthContext.name);

        if (!loggedIn) {
            ToastContext.display('You are logged out!');
        }

    }, [loggedIn, AuthContext.name, ToastContext]);

    const onLogout = (e) => {

        e.preventDefault();
        e.stopPropagation();
        AuthContext.logout();        
    }

    return(
        <>
        { loggedIn &&<Dropdown className={styles.userprofileWrapper__Large}>
            <Dropdown.Toggle variant='Secondary' id="dropdown-basic" className={styles.userButton}>
                <SvgUser />
            </Dropdown.Toggle>

            <Dropdown.Menu 
                align={{ lg: "right" }}>
                <Dropdown.ItemText >{userName}</Dropdown.ItemText>
                <Dropdown.Divider />
                <Dropdown.Item onClick={onLogout} >Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>}
        { loggedIn &&
        <>
            <Dropdown.Divider />
            <Nav className={styles.userprofileWrapper__Small}>
                <Nav.Link onClick={onLogout} >Logout</Nav.Link>
            </Nav>
        </>
        }
        </>
    )
}

export default UserProfile;
