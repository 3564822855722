
import {findIndex, propEq, pipe, find, prop, pluck, either} from "ramda";

const getSite = predicate => pipe(find( propEq(predicate.prop, predicate.target)));

const getGlobal = pipe(getSite({prop: 'isGlobal', target: 'true'}));

const getGlobalAdIds = sites => pipe(prop('slots'), pluck('slotPlacementId'))(getGlobal(sites))

/**
 * Retrieve index for a slot
 * @param ad
 * @param ads
 * @returns {number} the ad index or -1 if not found
 */
export const findSlotIndex = (ad, ads) => findIndex(either( propEq('adSlotId', ad?.adSlotId || 0), propEq('slotPlacementId', ad?.slotPlacementId || 0) ))(ads);

export const ads  = {
    getSite,
    getGlobalAdIds,
    getGlobal
}