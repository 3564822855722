import {transformLazyload, transformOffset} from "./utils";
import {
    propEq,
    clone,
    evolve,
    pipe,
    propOr,
    insert,
    reject,
    findIndex,
    remove, isNil
} from 'ramda';
import {placementIdByName} from "./placements";


/**
 * Removes actions based on type from a list
 * @param ignoredTypes {array}
 * @returns {function}
 */
export const rejectActionsByType = ignoredTypes => {
    return pipe(reject(item => ignoredTypes.includes(propOr('', 'type', item))));
}

// @TODO: Replace with logic from selectors.jsx, remove this
export const getHistoryForSite = name  => pipe(propOr({}, 'updates'), propOr([], name), clone);

//----------------------------------------------------------------
// Sites
//----------------------------------------------------------------
export const updateSite = (name, siteSettings, state) => {
    const { sites } = state;
    const index = findIndex(propEq('name', name), sites);
    let updatedList;
    if (index !== -1) {
        updatedList = pipe(
            remove(index, 1),
            insert(index, siteSettings))(sites)
    }
    else {
        console.error(`Unable to locate target for ${name}, settings not updated`); // @TODO: Error boundaries!
    }
    return updatedList;
}


export const transformSiteSetting = (settings, placements) =>  evolve({
        slots: transformSlots(placements),
        siteParentId: id => isNil(id) ? -1 : id
}, settings);


export const transformSlots = placements => slots => {
    return slots.map(
        slot => evolve({
            lazyload: transformLazyload(slot),
            slotPlacementId: placementIdByName(placements),
            offsetDesktop: transformOffset,
            offsetMobile: transformOffset
        }, slot)
    )
}

export const getIndexForKeyValue = (key, value) => findIndex(propEq(key, value))



