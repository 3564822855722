
import {useChangeLog} from "../../hooks/usechangelog";
import styles from './changelog.module.scss'
import React, { useState, useCallback } from "react";
import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';
import { map, pipe } from 'ramda';
import {isNotNil, random} from "../../helpers/utils";

const resolveMessage = (verb, key, targetValue, adSlotName) => {
    switch (verb) {
        case 'changed':
            return `${isNotNil(adSlotName) ? adSlotName : ""}: ${verb} ${key} to ${targetValue}`;
        case 'removed':
            return `${adSlotName}: ${verb} ${targetValue} from ${key}`;
        case 'deleted':
            return `${adSlotName}: ${verb} ${targetValue} from ${key}`;
        case 'added':
            return `${adSlotName}: ${verb} ${targetValue} to ${key}`;
        default:
            return `${adSlotName}: ${verb} ${key} ${targetValue}`;
    }
}

const resolveDescFromLogItem = item => {
    if (item) {
        const { value, adSlotName } = item;
        const { verb, key, targetValue } = value;
        return resolveMessage(verb, key, targetValue, adSlotName);
    }
}

const logRow = (desc, length = undefined) => (
    <span key={random(10, 100)} className={styles.changelog_row}>{desc}  </span>
)
const showRowFromLogItem = pipe(resolveDescFromLogItem, logRow)

const showAllRows = map(showRowFromLogItem)

const Chevron = props => {
    return (
        <svg className={styles.chevron}
            width={10}
            height={6}
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>{"noun_collapse_2625924"}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M-546-244H785v609a8 8 0 01-8 8H-538a8 8 0 01-8-8v-609z"
                    fill="#FFF"
                />
                <path d="M1 .385L5 5 9 .385" stroke="#333" />
            </g>
        </svg>
    )
}

const Changelog = props => {
    const { name } = props;
    const log = useChangeLog(name);
    const [ showAll, setShowAll ] = useState(false);
    const toggleState = useCallback(() => setShowAll(!showAll), [showAll]);
    return (
            <div onClick={toggleState} className={`${styles.changelog} ${showAll ? styles.changelog_open : ""}`}>
                <div className={styles.changelog_wrapper}>
                    <Chevron/>
                    <span className={styles.changelog_title}>View log</span>
                    <Badge variant="secondary">{ log?.length || 0}</Badge>
                </div>
                { showAll && log && showAllRows(log) }
            </div>
    )
}

Changelog.propTypes = {
    name: PropTypes.string,
};

Changelog.defaultProps = {
    name: '',
}

export default React.memo(Changelog);