import {last, pipe, propOr, reject, without, clone, cond, identity, T, always} from "ramda";
import {ROLLBACK_SETTINGS, UNDO, UNEXPECTED_RESULT} from "../components/stores/sites/types";
import {makeRollbackAction} from "./actions";
import {isValidAction} from "./sites";



export const selectHistory = pipe(propOr({}, 'updates'), clone);

export const selectHistoryForSite = name => pipe(selectHistory, propOr({}, name), clone);

export const getLatestHistoryState = name => pipe(selectHistoryForSite(name), last, clone);

const getCurrentFallback = name => pipe(
    getLatestHistoryState(name),
    cond([
            [isValidAction, identity],
            [T, always({type: UNEXPECTED_RESULT})]
        ],
));


/**
 * Updates global history by removing an action for a specific site
 * @param siteName {string} Site to remove action from
 * @param action {object} Target for removal
 * @param state {object} Updated global history
 */
const removeAndUpdateHistory = (siteName, action, state) => {
    const history = selectHistory(state);
    let { next, diff } = removeActionFromHistory(siteName, action, state);

    if (diff !== 1) {
        console.warn('Unexpected difference between new and previous history state');
    }

    const updatedHistory = purgeEmptyHistoryLists({ ...history, [siteName]: next })
    return updatedHistory;
}


export const removeActionFromHistory = (siteName, action, state) => {
    const previous = selectHistoryForSite(siteName)(state);
    const next = without([action], previous);
    const diff = Math.abs(previous.length - next.length);

    return {previous, next, diff};
}

export const addActionToHistory = (name, action, history) => ({ ...history, [name]: [action]});

/**
 * Remove all history for a specific site
 * @param name {string} Target site
 * @param history {array}
 */
export const purgeHistory = (name, history) => purgeEmptyHistoryLists({ ...history, [name]: [] });

/**
 * Remove root level for lists that have no children
 */
export const purgeEmptyHistoryLists = list => reject(target => target.length === 0)(list);

/**
 * Update history with a new action
 * @param type
 * @param prevSettings {object}
 * @param siteHistory
 * @param history
 * @returns {*|{}}
 */

export const updateHistoryPostSave = (type, prevSettings, siteHistory, history) => {

    const { name } = prevSettings;


    let updatedHistory = siteHistory;
    if (type === ROLLBACK_SETTINGS) {
        updatedHistory = purgeHistory(name, history);
    }
    else if (type === UNDO) {
        const rollbackAction = makeRollbackAction( siteHistory, prevSettings);

        updatedHistory = addActionToHistory(name, rollbackAction, history);
    }
    else {
        console.error('Invalid type for post update action, all history will be purged')
        updatedHistory = {};
    }

    return updatedHistory;
}


export const history = {
    removeAndUpdateHistory,
    getCurrentFallback,
    getCurrent: getLatestHistoryState
}

export default history