import styles from "../settingsVerticals.module.scss";
import React, { useState, useContext, useEffect, useMemo, useCallback} from 'react';
import {
    pipe,
    apply,
    zip,
    path,
    map,
    propOr, identity
} from 'ramda';
import Form from "react-bootstrap/Form";
import verticalsContext from "../../stores/verticals/verticalsContext";
import {PAGEVERTICALS_UPDATED} from "../../stores/verticals/types";
import {useEffectExceptOnMount} from "../../../helpers/utils";
import {useAxios} from "../../../hooks/useaxios";
import Button from "react-bootstrap/Button";
import AddVerticals from "../../addverticals/addVerticals";



// Adapt verticals and id's to have same format as pre-existing verticals
const transformVerticals = pipe( apply(zip), map( item => ({ verticalId: item[1], vertical: item[0]})) );


const inputTextValue = pipe(path(['target', 'value']));

const usePrevNewValues = source => {


    const [previousValue, setPreviousValue] = useState(source || 'empty');
    const [newValue, setNewValue] = useState(source || 'empty');
    const update = (prev, next) => ({prev, next, hasChanged: prev !== next});
    const [value, setValue] = useState(update(previousValue, newValue));
    const handleChange = pipe(inputTextValue, setNewValue);

    React.useEffect( () => setNewValue( previousValue, update(inputTextValue(previousValue))), [previousValue]);

    React.useEffect( () => setValue( update(previousValue, newValue)), [newValue, previousValue]);

    React.useEffect( () => setPreviousValue( value.next), [value]);

    
    return { newValue, previousValue, value, handleChange };
}

const TextField = ({onChange, startValue, label, prefix = false}) => {

    const {value, handleChange} = usePrevNewValues(startValue);

    const onValueChange = () => {

        if (value.hasChanged) {
            onChange(value);
    }}

    useEffect(onValueChange, [value]);

    return (
        <div className={styles.settings_slug}>
            <Form.Label>{label}:</Form.Label>
            { prefix && <p>{prefix}/ </p>}
            <Form.Control
                value={value.next}
                onChange={handleChange}
                autoComplete={'nope'}
                size="md"
                type="text"
                placeholder="Enter slug or leave empty for main domain" />
        </div>
    )
}

const VerticalRow = ({verticalIds = [], verticals = [], slug, onChange, index, ...rest}) => {
    const [row, setRow] = useState({verticals: transformVerticals([verticals, verticalIds]), slug});

    const updateRowContent = (name, content) => {

        setRow({...row, [name]: content});
    } 
    
    const onChange1 = name => content => updateRowContent(name, content);

    useEffectExceptOnMount(() => {

            onChange(row, index);
    }, [row])

    return (
        <div className={styles.vertical_slug__row} >
            <TextField onChange={onChange1('slug')} label={'Slug'} startValue={row.slug} prefix={rest.domain}/>
            <AddVerticals addNew={false} selected={row.verticals} onCallback={onChange1('verticals')} automaticCallback={true}/>
        </div>
    )
}

const reqParams = (url, method, data = {}, completeAction = null) => ({
    url,
    method,
    data,
    completeAction,
    parseResponse: pipe(propOr(identity, 'data'))
})

const Rows = ({verticals, prefix, ...rest}) => {
    return (
        <>

        { verticals.length > 0 && verticals.map( (vert, i) => (

                <VerticalRow             
                    key={vert.id || `${prefix}-${i}`}
                    index={i}
                    {...rest}
                    {...vert}
                />
                )        
            )}
        </>
    )    
}

const reverseTransform = (pageVertical) => {

    const vert = pageVertical.map( item => item.vertical ) || [];
    const vertIds = pageVertical.map( item => item.verticalId ) || [];
    return { vert, vertIds }
}


const SettingsVerticals = ({gamSiteId}) => {

    const VerticalContext = useContext(verticalsContext);
    const [verticals, setVerticals] = useState([])
    const [keyPrefix] = useState(Date.now());

    const { loading, setRequest } = useAxios(VerticalContext.dispatch);

    
    // todo: dont load data if we already have it //
    useEffect( () => {

        VerticalContext.fetchPageVerticals(gamSiteId)

    }, [gamSiteId]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        setVerticals(VerticalContext.pageVerticals[gamSiteId] || []);

    }, [VerticalContext.pageVerticals[gamSiteId]]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (content, index) => {

        let newVerticals = [...verticals];

        const slug = content.slug.hasChanged === true ? content.slug.next : content.slug;
        const { vert, vertIds } = reverseTransform(content.verticals);

        newVerticals[index] = { verticals: vert, verticalIds: vertIds,slug: slug };    

        setVerticals(newVerticals);
    }

    const addNewSlug = (e) => {

        (e.target && e.target.blur())

        setVerticals([...verticals,
            { 
                verticals: [],
                verticalIds: [],
                slug: ""
            }
        ]);
    }

    const countEmptySlug = useCallback(() => {

        let count = 0;
        if (verticals.length > 0) {
            verticals.forEach((vert) => {

                if ( vert.slug === "" && (vert.verticals.length > 0) ) {
                    count++;
                }
            });
        }
        return count;
    }, [verticals])

    const tooManyEmptySlugs = useMemo(() => (countEmptySlug() > 1 ), [countEmptySlug]);

    const allowSubmit = useMemo(() => (!tooManyEmptySlugs && !loading), [loading, tooManyEmptySlugs]);

    const submit = () => {
        const options = reqParams(`/pageVerticals/${gamSiteId}`, 'PUT', verticals, PAGEVERTICALS_UPDATED);

        setRequest(options);
    }

    return (
        <div className={styles.verticals_container}>
            <p>
                Here below you enter unique slugs and associated verticals.<br/>
                The blank slug indicates verticals for the home page of the current domain.<br/>
                Leave blank for verticals if you wish to delete the slug.<br/>
                The list of verticals are managed in Options.<br/>
            </p>
            <Rows onChange={onChange} verticals={verticals} domain={gamSiteId} prefix={keyPrefix}/>

            <div className={styles.verticals__footer}>
                {tooManyEmptySlugs && (<p>Too many empty slugs!</p>) }
                <Button variant="outline-secondary" onClick={addNewSlug} className={styles.verticals_newSlugButton}>
                    Add new slug
                </Button>
                <Button onClick={submit} disabled={!allowSubmit}>
                    Save
                </Button>
            </div>

        </div>
    )
}

export default SettingsVerticals;