import {
    DELETE_SLOT,
    GET_SITES,
    GET_SITE,
    ROLLBACK_SETTINGS_COMPLETE,
    SITE_UPDATED,
    UNDO,
    ROLLBACK_SETTINGS,
    UPDATE_SLOT,
    UPDATE_METADATA
} from "./types";
import {reject, pathOr, sortBy, prop} from 'ramda';
import {updateSite, transformSiteSetting,} from "../../../helpers/modelHelper";
import {
    updateSiteHistory,
    getActiveSettings,
    updateSlotAtIndex,
    updateStateHistory,
    updateProp, transformSites
} from "../../../helpers/sites";
import {ads} from "../../../helpers/ads";
import {selectResponseDataFromAction} from "../../../helpers/selectors";
import {selectHistory, history, removeActionFromHistory, updateHistoryPostSave} from "../../../helpers/history";
import {unwrapAction} from "../../../helpers/actions";
import {errorIf, isNotEmpty} from "../../../helpers/utils";
import {findSlotIndex} from "../../../helpers/ads";

const updateSlot = (state, action) => {
    const { payload } = action;
    const {siteName, adSlot, placements } = payload;
    if (isNotEmpty(adSlot)) {
        const currentSiteSettings = getActiveSettings(siteName, state);
        const slotIndex = findSlotIndex(adSlot, currentSiteSettings.slots);
        const updatedAdSlot = "siteId" in adSlot ? adSlot : { ...adSlot, siteId: currentSiteSettings?.siteId };
        const updatedSite = updateSlotAtIndex(slotIndex, updatedAdSlot, currentSiteSettings);
        const siteSettings = transformSiteSetting(updatedSite, placements);
        const updates = updateSiteHistory(siteName, siteSettings, state.updates);
        return updateStateHistory(updates, state);
    }
    return state;
}

const updateMetadata = (state, action) => {

    const { payload } = action;
    const { siteName, prop, value } = payload;

    const currentSiteSettings = getActiveSettings(siteName, state);
    const updatedSite = updateProp(prop, value, currentSiteSettings)
    const updates = updateSiteHistory(siteName, updatedSite, state.updates);

    return updateStateHistory(updates, state);
}

/**
 * Deletes an adSlot object from the the latest available site settings
 * @param state {object}
 * @param payload {object}
 * @returns {object}
 */
 const deleteSlot = (state, payload) => {
    const { adSlot, siteName } = payload;
    const { updates } = state;

    const currentSettings = getActiveSettings(siteName, state);
    const slots = currentSettings?.slots || [];
    let purged = reject(item => item.slotPlacementId === adSlot.slotPlacementId, slots);  // previous "adSlotId"
    if(adSlot.isNested){
        delete adSlot.isNested;
        purged.push(adSlot);
    }
    purged = sortBy(prop('slotPlacementId'), purged);
    const updatedSettings = { ...currentSettings, slots: [...purged]};

    if (slots.length === purged.length) {
        console.error(`Unable to remove item ${adSlot.adSlotId}`);
    }
    const updatedHistory = updateSiteHistory(siteName, updatedSettings, updates);
    return updateStateHistory(updatedHistory, state);
}


/**
 * Update the site store with new settings, modify history after type of the applied update.
 * @param state {object}
 * @param payload {object}
 * @returns {object}
 */
const saveSettingsComplete = (state, action) => {
    const { payload } = action;
    const { appliedAction } = payload;
    const { siteName, type } = unwrapAction(appliedAction);

    // Remove the action just stored
    const {next, diff} = removeActionFromHistory(siteName, appliedAction, state);

    errorIf('Unexected length of history', diff === 0)

    // update history for site according to the action type
    const e = { ...state, updates: { [siteName]: next}};
    const prevActive = getActiveSettings(siteName, e, [ROLLBACK_SETTINGS]);

    const globalHistory = selectHistory(state);
    const updates = updateHistoryPostSave(type, prevActive, next, globalHistory);

    // Update site store with the updated settings
    const latestSettings = selectResponseDataFromAction(action);
    const sites = updateSite(siteName, latestSettings, state);

    return { ...state, sites, updates, updateDesc:  `${siteName} successfully updated`};
}


/**
 * Parse the response from the network request and update state
 * @param state
 * @param action
 * @returns {object}
 */
const fetchSitesComplete = (state, action) => {
    const sites_ = transformSites(action.payload.response);   // Sorting
    const globalSlotIDs = ads.getGlobalAdIds(sites_);

    return { ...state, sites: sites_, globalSlotIDs, updates: {} }
}

const getSite = (state, action) => {
    let nextState = state;
    const settings = pathOr(undefined, ['payload', 'response', 'data'], action);
    const { name } = settings;

    if (settings && name) {
        nextState = { ...state, sites: [ ...state.sites, settings ], updateDesc:  `${name} successfully created`};
    }

    return nextState;
}


/**
 * Fetch & create a network request based on the last update action
 */
const UndoRollback = (state, action) => {
    const { payload } = action;
    const { settings } = payload;
    const transformedSettings = transformSiteSetting(settings, []);
    const request = { method: 'put',  url: `site/${settings.siteId}`, data: {...transformedSettings}, completeAction: ROLLBACK_SETTINGS_COMPLETE };
    return {...state, request }
}

const undoUpdate = (state, action) => {
    const { siteName } = unwrapAction(action);
    const allHistory = history.removeAndUpdateHistory(siteName, action, state);
    return {...state, updates: { ...allHistory }}
}


const rollbackApplied = (state, action) => {
    const latestSettings = selectResponseDataFromAction(action);
    const sites = updateSite(latestSettings.name, latestSettings, state);
    return { ...state, sites: [...sites], updates: {}};
}

export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case DELETE_SLOT:
            return deleteSlot(state, payload);

        case SITE_UPDATED:
            return saveSettingsComplete(state, action);

        case UNDO:
            return undoUpdate(state, action);

        case UPDATE_SLOT:
            return updateSlot(state, action);

        case ROLLBACK_SETTINGS:
            return UndoRollback(state, action);

        case ROLLBACK_SETTINGS_COMPLETE:
            return rollbackApplied(state, action);

        case GET_SITES:
            return fetchSitesComplete(state, action);

        case GET_SITE:
            return getSite(state, action);

        case UPDATE_METADATA:
            return updateMetadata(state, action)

        default:
            return state;
    }
}