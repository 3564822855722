import * as React from "react";

const SvgCollapse = (props) => (
    <svg width="43px" height="43px" viewBox="0 0 43 43">
        <g id="aller" stroke="none" fill="none">
            <g id="elle-add-size" transform="translate(-1309.000000, -368.000000)">
                <path d="M55,353 L1386,353 L1386,962 C1386,966.418278 1382.41828,970 1378,970 L1326.64936,970 L1326.64936,970 L63,970 C58.581722,970 55,966.418278 55,962 L55,353 L55,353 Z"></path>
                <circle id="Oval" stroke="#333333" cx="1330.5" cy="389.5" r="20.5"></circle>
                <g id="chevron" transform="translate(1331.000000, 390.000000) rotate(-180.000000) translate(-1331.000000, -390.000000) translate(1323.000000, 386.000000)" stroke="#333333">
                    <polyline id="Path-2" points="16 7.38461538 8 2.36118817e-12 0 7.38461538"></polyline>
                </g>
            </g>
        </g>
    </svg>
    );

export default SvgCollapse;