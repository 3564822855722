import {ON_ERROR, PREP_REQUEST} from "./types"

export default (state, action) => {
    const { payload, type } = action
    switch (type) {
        case ON_ERROR:
            return {...state, error: {...payload}}
        case PREP_REQUEST:
            return {...state, error: {}}
        default:
            return state
    }
};