import {GET_SIZES} from "./types";

export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_SIZES:
            return {
                ...state,
                sizes: [...payload.data]
            };
        default:
            return state;
    }
}


