import Button from "react-bootstrap/Button";
import React, {useState, useEffect, useContext} from "react";
import {Typeahead, Menu, MenuItem, Highlighter} from "react-bootstrap-typeahead";
import verticalsContext from "../stores/verticals/verticalsContext";
import {ifElse, pipe, is, addIndex, map, converge, has, prop, propOr, concat} from "ramda";
import styles from "./addverticals.module.scss";
import {useEffectExceptOnMount} from "../../helpers/utils";
import toastContext from "../stores/toast/toastcontext";

const NEW_PREFIX = 'Add new: ';
const LABEL_KEY = 'vertical';

const propWithPrefix = pipe(prop(LABEL_KEY), concat(NEW_PREFIX));

const getContent = pipe( ifElse( has('customOption'), propWithPrefix, prop(LABEL_KEY)));

const getSearch = pipe(propOr('', 'text'));

const makeHighLight = ({search, content}) => (
    <Highlighter key={Date().now} search={search}>
        {content}
    </Highlighter>
);

const mapHighLight = converge(
    (content, search) => ({search, content}),
    [ ({option}) => getContent(option),
        ({state}) => getSearch(state)]
)


const mapIndexed = addIndex(map)

const makeMenu =  onlyNewItems => (results, menuProps, state) => (
    <Menu {...menuProps}>
        {
            mapIndexed( (option, position) =>   (
                        <MenuItem disabled={onlyNewItems && !has('customOption', option)} key={position} option={option} position={position}>
                            { pipe(mapHighLight, makeHighLight)({ option, state }) }
                        </MenuItem>
                    )

        , results) }
    </Menu>
);


const AddVerticals = ({ onCallback, allowNew, selected, automaticCallback = false }) => {

    const [verticals, setVerticals] = useState(selected || []);
    const ToastContext = useContext(toastContext);

    const VerticalsContext = useContext(verticalsContext);
    const handleChange = t => {
        setVerticals(t);
    }


    useEffect(() => {

        if(automaticCallback){
            Array.isArray(verticals) && (typeof onCallback === 'function') && onCallback(verticals);
        }
    }, [verticals]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffectExceptOnMount(() => {

        if(!automaticCallback && verticals.length > 0 ){
            setVerticals([]);
            ToastContext.display(`List of verticals has been updated`)
        }
    }, [VerticalsContext.verticals])

    const executeCallback = () => (verticals.length > 0 && is(Function, onCallback) && onCallback(verticals));

    const handleSubmit = e => {

        e.preventDefault();
        executeCallback();
    };



    return (
        <div className={styles.admin_verticals}>
            <Typeahead
                onChange={handleChange}
                options={VerticalsContext.verticals}
                renderMenu={makeMenu(allowNew)}
                multiple={true}
                selected={verticals}
                labelKey={'vertical'}
                id={'typeahead'}
                placeholder={'Click to add new vertical'}
                allowNew={allowNew}/>

            { !automaticCallback &&
                <Button type='submit' onClick={handleSubmit} className={styles.addSiteButton__Small}>
                    Add selected
                </Button> }

        </div>
    )

}

export default AddVerticals;