import {
    pipe,
    prop,
    find,
    is,
    identity,
    reject,
    __,
    F,
    assoc,
    T,
    reduce,
    useWith as _useWith,
    map,
    isEmpty,
    ifElse,
    pluck,
    always,
    without, isNil, complement, propOr, includes, propEq, path, filter
} from 'ramda'
import {deepCopy, sortStrings} from "./utils";

const findPlacementById = _useWith(find, [propEq('slotPlacementId'), identity]);

export const findPlacementNameById = (val, placements) => pipe(findPlacementById, propOr(val, 'adSlotName'))(val, placements);

/** TODO: Remove! Messy and makes assumptions that we shouldn't do **/
export const placementNameByID = (placements, slotPlacementId) => pipe(
    find(p => p.slotPlacementId === parseInt(slotPlacementId, 10)),
    prop('adSlotName'),
    ifElse( is(String), identity, always('')))
(placements);

/** Pretty much the cade as placementByName on top **/
export const placementIdByName = placements => name => pipe(
    find(p => p.adSlotName === name),
    prop('slotPlacementId'),
    ifElse( is(Number), identity, always(name)))
(placements);

/** TODO: Remove! Should not assume we can apply parseInt to all id's **/
const findById = Id => find(propEq('slotPlacementId', parseInt(Id, 10)))

const isPlacementByName = name => pipe(
        find(placement => placement.adSlotName === name),
        ifElse(isNil, F, T)
);

export const isNotPlacementByName = name => complement(isPlacementByName(name));

export const isGlobalPlacement = id => pipe(
    propOr([], 'globalSlotIDs'),
    includes(id)
);

/**
 * Returns an object with slotPlacementId:adSlotName for key:value
 * @type {function}
 */
export const toPlacementObject = reduce((acc, curr) =>
    assoc(curr.slotPlacementId, curr.adSlotName, acc), {});

/**
 * Filter placement list not to contain items existing in {slots}
 * @param placements
 * @param slots
 * @returns {array}
 */
// Removed memoizeWith(identity) because it was always returning the
// cached value of the first function call.
// This is may be due to R.identity not detecting any change from the
// reference of the parameters.
export const getLocalPlacements = (placements, slots) => {
    const isEnableTrue = propEq('isEnable', 1);
    const filteredSlots = filter(isEnableTrue, slots);
    const localSlotPlacementIDs = pluck('slotPlacementId', filteredSlots);
    const globalNames = pluck('adSlotName', placements)
    const localGlobalNames = pipe(
            map( itm => placementNameByID(placements, itm)),
            reject(isEmpty),
            without(__, globalNames),
            sortStrings
        )(localSlotPlacementIDs)

    return localGlobalNames
};

export const placementUtils = {
    getLocalPlacements,
    isPlacement: isPlacementByName,
    isNotPlacement: isNotPlacementByName,
    isGlobalPlacement,
    findById
}

/** 
 * Merged child site's placement with Parent placement
 * @param currentSite
 * @param parentSlot
 * @returns {arrays} merged placement slot of parent-child
*/

const mergeWithParent = (currentSite, parentSlot) => {
    const copiedCurrentSite = path(['slots'], deepCopy(currentSite))
    let mergedWithParentSite = deepCopy(copiedCurrentSite);
    parentSlot && parentSlot.length > 0 && parentSlot.forEach((parentSlotItem) => {
        const isNotExistCurrentSlot = isEmpty(filter(propEq('slotPlacementId', parentSlotItem.slotPlacementId))(copiedCurrentSite));
        if (isNotExistCurrentSlot) {
            parentSlotItem.isParentInherited = true;
            parentSlotItem.sizes = [];
            mergedWithParentSite.push(parentSlotItem);
        }
    });
    return mergedWithParentSite;
}

/** 
 * Merge global slot with local if global slot not exist in local site
 * @param globalSlot
 * @param currentSourceData
 * @param mergedArray
 * @returns {arrays} merged placement slot
*/

const concatGlobalLocalSlots = (globalSlot, currentSourceData, mergedArray) => {
  !isEmpty(globalSlot) &&
    globalSlot.forEach((globalSlotItem) => {
      const isNotExistCurrentSlot = isEmpty(
        filter(propEq("slotPlacementId", globalSlotItem.slotPlacementId))(
          currentSourceData
        )
      );
      if (isNotExistCurrentSlot) {
        if (propEq("isEnable", 1)(globalSlotItem)) {
          globalSlotItem.isInherited = true;
          globalSlotItem.sizes = [];
          mergedArray.push(globalSlotItem);
        }
      }
    });
  return mergedArray;
};

/** 
 * Merged current site's placement with Global placement
 * @param currentSource
 * @param globalSource
 * @param allSource
 * @returns {arrays} merged placement slot
*/

export const mergeGlobalWithCurrentSource = (currentSource, globalSource, allSource) => {
    const isGlobalSection = currentSource.isGlobal;
    const currentGamSiteId = currentSource.gamSiteId;
    const copiedCurrentSource = path(['slots'], deepCopy(currentSource));
    const copiedGlobalSource = deepCopy(globalSource);
    const copiedAllSource = deepCopy(allSource);
    const globalSlot = path(['slots'], copiedGlobalSource);
    const findByGamSiteId = site => site?.gamSiteId !== currentGamSiteId && !!currentGamSiteId?.endsWith(site.gamSiteId);
    const parentSlotData = copiedAllSource.find(findByGamSiteId)?.slots;
    let mergeWithParentResponse ;
    if(parentSlotData !== undefined || parentSlotData !== null){
        mergeWithParentResponse = mergeWithParent(currentSource, parentSlotData)
    }
    else mergeWithParentResponse = [];
    let copiedParentResponse = deepCopy(mergeWithParentResponse);
    let mergedSlot = deepCopy(copiedCurrentSource);

    if ( isGlobalSection === "false" && !isNil(parentSlotData)) {
        return concatGlobalLocalSlots(globalSlot, mergeWithParentResponse, copiedParentResponse);
    }
    else if(isGlobalSection === "false"){
        return concatGlobalLocalSlots(globalSlot, copiedCurrentSource, mergedSlot);   
    }
    else return currentSource.slots; 
}