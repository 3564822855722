import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {useIsLoggedIn} from "../hooks/useloginstate";

const ProtectedRoute = props => {

    const {loggedIn} = useIsLoggedIn();

    return (
        <Route default path={props.path}>
            {loggedIn && props.children}
            {!loggedIn && <Redirect to='/login'/>}
        </Route>
    )
}

export default ProtectedRoute;