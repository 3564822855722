export const GET_SITES = "GET_SITES";
export const GET_SITE = "GET_SITE";
export const UPDATE_SLOT = 'update_slot';
export const UPDATE_METADATA = 'updatemetadata';
export const SITE_UPDATED = 'site_updated';
export const DELETE_SLOT = 'delete_slot';
export const UNEXPECTED_RESULT = 'unexpected_result';
export const UNDO = 'undo';
export const ROLLBACK_SETTINGS = 'ROLLBACK_SETTINGS';
export const ROLLBACK_SETTINGS_COMPLETE = 'settings rollback';

