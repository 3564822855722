import React, { useState, useEffect, useCallback} from 'react';
import Form from 'react-bootstrap/Form';
import styles from "./metadata.module.scss";
import {
    equals,
    curry,
    pipe,
    slice,
    concat,
    match,
    join,
    toUpper,
    path,
    map,
    pathOr
} from "ramda";
import PropTypes from "prop-types";
import {random} from "../../helpers/utils";
import Collapsable from "../collapseable/collapsable";
import metaDefinitions from "./definitions";

const isStringTrue = equals('true');

const upperFirst = pipe(path([0]), toUpper);

const sliceFirst = pipe(slice(1, Infinity));

const capitalizeFirst = str => concat(upperFirst(str), sliceFirst(str))

const camelCaseToWhitespace = pipe(capitalizeFirst, match(/[A-Z][a-z]+/g), join(' '));

const MetaToggle = React.memo(props => {
    const {label, propKey, value, onChange} = props;
    const handleChange = useCallback( e => onChange(String(e.target.checked)), [onChange]);
    const resolvedValue = typeof value === 'string' ? isStringTrue(value) : value;

    return (
        <Form.Group key={propKey} className={styles.metadata}>
            <Form.Label>{label}</Form.Label>
            <Form.Check
                type="switch"
                id={propKey + random(100, 10000)}
                label={resolvedValue ? 'On' : 'Off'}
                checked={resolvedValue}
                onChange={handleChange}
            />
        </Form.Group>
    )
});

const MetaInputValue = React.memo(props => {
    const { label, propName, value, size, onUpdated, frontPageadPlaceholder } = props;
    const [localValue, setLocalValue] = useState(value);
    const sizeClassName = `meta_input_${size}`;

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleChange = useCallback( e => {
         setLocalValue(e.target.value)
    }, [localValue] ); // eslint-disable-line react-hooks/exhaustive-deps

    const handleBlur = useCallback( e => {
        onUpdated(localValue)
    }, [localValue, onUpdated] )
    return (
        <div className={`${styles.metadata_input_group} ${styles[sizeClassName]} ${frontPageadPlaceholder ? styles['custom-placeholder'] : ''}`}>
                <Form.Label>{label || camelCaseToWhitespace(propName)}:</Form.Label>
                <Form.Control
                    value={localValue ? localValue : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete={'nope'}
                    size="sm"
                    type="text"
                    placeholder = {!localValue && !value && frontPageadPlaceholder ? frontPageadPlaceholder : ''} /> 
            </div>
    )
}, (prev, props) => {
    return prev.value === props.value;
});

/**
 * Mapped site settings for toggle options
 */
const adServices = [
    ['Adnami', 'enableAdnami'],
    ['demand manager', 'enableDemandManager'],
    ['top ads', 'enableWallpaperAds'],
    ['Ad watch', 'statscollector.enabled']
];

const Metadata = React.memo(props => {

    const { onMetadataChange, settings } = props;
    const onChange = useCallback( curry(onMetadataChange), [settings]);
    const ToggleOption =option => {
    const [displayName, propName] = option;
    const propPath = propName.split('.');
    const propValue = pathOr(false, propPath, settings);

    return (
            <MetaToggle label={displayName}
                        propKey={option[1]}
                        key={option[1]}
                        value={propValue}
                        onChange={ onChange(settings.name, option[1]) } />
            )
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const ToggleOptions = props => map(ToggleOption, props.options);

    /**
     * Map a single setting to a text input field
     */
    const InputOption = useCallback( (name, input, {headline, desc, label, pattern}) => {

        const path = input.split('.')
        const prop = path[path.length - 1]
        const [isValid, setIsValid] = useState(true);
        const current = pathOr('', path, settings)

        const onLocalChange = updatedInput => {
            if (current !== updatedInput) {
                const valid = pattern ? pattern.test(updatedInput) : true;
                valid && onChange(name, input, updatedInput)
                setIsValid(valid);
            }
        }

        return (
        <div key={input} data={true}
            className={!isValid ? styles.metadata_invalid : null}>
            { headline && <h6>{headline}</h6> }
            { desc && <p>{desc}</p>}
            <MetaInputValue
                propName={prop}
                label={label || prop}
                key={prop}
                onUpdated={onLocalChange}
                value={current}
                size={'medium'}
                frontPageadPlaceholder= {path[0] === "frontPageAd" ? settings.frontpageadsPlaceholder[prop] : ''}
            />
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    )}, [onChange]);
    
    const propertySetting = setting => {
        return typeof setting === 'object' ? setting : { prop: setting } 
    }

    /**
     * Map a single or multiple grouped settings to input fields
     */
    const InputOptions = (props) => {
        const {input} = props;
        if (typeof input === 'object' && input.children) {

            return input.children.map(child => {
                const childSettings = propertySetting(child);
                const propPath = input.path ? `${input.path}.${childSettings.prop}` : `${childSettings.prop}`
                return InputOption(settings.name, propPath, childSettings)
            })
        }
    };

    /**
     * Map inputs to controls
     */
    const InputGroups = props => props.groups.map( (item, i) => {

        return (
            <div key={i}>
                { !(settings.isGlobal === 'true' && item.skipGlobal) &&
                    <Collapsable defaultOpen={false} key={i} title={item.displayName}>
                        <InputOptions input={item} />
                    </Collapsable>
                }
            </div>
        )
    })

    return (
        <div className={styles.metadata_wrapper}>
            <div className={styles.metadata_props}>
                <Collapsable title='Ad services'>
                    <div className={styles.props_container}>
                       <ToggleOptions options={adServices} />
                    </div>
                </Collapsable>
                <div className={styles.props_container}>
                    { <InputGroups groups={metaDefinitions}/> }
                </div>
            </div>

        </div>
    )
});

Metadata.propTypes = {
    onMetadataChange: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
};

export default Metadata;
