import { useEffect, useMemo, useContext, useCallback, useState } from "react";

import {
    reduce,
    propOr,
    map,
    where,
    equals,
    both,
    prop,
    pipe,
    either,
    curry,
    pathOr,
    objOf,
    always,
    mergeLeft,
    isNil,
    ifElse,
    complement
} from 'ramda'


import sitesContext from "../components/stores/sites/sitescontext";
import placementContext from "../components/stores/placement/placementscontext";
import {changeLog} from "../helpers/changelog";
import {getAllUpdatesFor, getIdFromPosition, getNames} from "../helpers/sites";



const isUpdatedSlot =  where({
    verb: either(equals('deleted'), equals('added')), key: equals('slot')
});

const isNotNil = complement(isNil);


export const useChangeLog = siteName => {

    const SitesContext = useContext(sitesContext);
    const PlacementContext = useContext(placementContext);
    const [ log, setLog ] = useState({})


    const resolveUpdatedSlot = pipe(
            propOr(undefined, 'value'),
            ifElse(
                both(isNotNil, isUpdatedSlot),
                prop('targetValue'),
                always(undefined)
    ));



    const resolveIdOther =  pipe(
        propOr(undefined, 'index'),
        curry(getIdFromPosition)(siteName));

    const fetchNameAndMerge  = target =>  pipe(
        PlacementContext.getNameFromID,
        pathOr(undefined, [0]),
        ifElse(isNil,
            always(target),
            pipe(objOf('adSlotName'), mergeLeft(target))
        )
    )

    const decorateWithPlacementName = useCallback( logItem => {
        const target = resolveIdOther(logItem) || resolveUpdatedSlot(logItem) || undefined;
         return fetchNameAndMerge(logItem)(target);
    }, [SitesContext, PlacementContext, siteName]); // eslint-disable-line react-hooks/exhaustive-deps

    const getLog = useMemo(() => {
        const siteNames = getNames(SitesContext.state.sites);
        const diffLog = reduce((acc, name) => {
            const updates = getAllUpdatesFor(name)(SitesContext.state);

            if (updates.length > 1) {
                const diff = changeLog.buildFromList(updates)
                acc[name] = map( decorateWithPlacementName, diff);
            }


            return acc
        }, {}, siteNames)

        return diffLog;
    }, [SitesContext.state, decorateWithPlacementName]);

    useEffect(() => {
        setLog(getLog)
    }, [SitesContext.state, getLog]);


    return log[siteName];
}


