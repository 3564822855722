import React, {useState, useContext, useEffect, useMemo} from 'react';
import AdSlot from "../adslot/adslot";
import styles from "./sitesetting.module.scss";
import placementContext from "../stores/placement/placementscontext";
import networkContext from "../stores/network/networkContext";
import sitesContext from "../stores/sites/sitescontext";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Changelog from '../changelog/changelog';
import {AlertErrorExclamation} from "../alert/errorAlert";
import {ROLLBACK_SETTINGS} from "../stores/sites/types";
import Metadata from "../metadata/metadata";
import ConfirmButton from "../confirmButton/confirmButton";
import {deepCopy, parseBool} from "../../helpers/utils";
import {getLocalPlacements, mergeGlobalWithCurrentSource} from "../../helpers/placements";
import {mergeFrontPageAdsGlobalWithCurrentSource} from "../../helpers/frontPageAd";
import {getActiveSettings, hasTextFieldError, siteHasNotUnsavedChanges} from "../../helpers/sites";
import { findIndex, propEq } from 'ramda';
import Collapsable from "../collapseable/collapsable";
import { AuthBasedRender } from "../stores/auth/authbasedrender";
import { AuthRoles } from "../stores/auth/authRole";


const SiteSetting = props => {
    const {site} = props;
    const {name, siteId} = site;
    
    const PlacementsContext = useContext(placementContext);
    const NetworkContext = useContext(networkContext);
    const SitesContext = useContext(sitesContext);
    const globalIndex = useMemo(()=> findIndex(propEq('isGlobal', 'true'))(SitesContext.sites)); // eslint-disable-line react-hooks/exhaustive-deps
 
    const [siteSource, setSiteSource] = useState(site);
    const [mergedSourceSlots, setMergedSourceSlots] = useState(SitesContext.sites[globalIndex].slots);

    const hasError = NetworkContext.error && NetworkContext.error.targetSiteId === siteId;

    const onDeleteSlot = (adSlot) => SitesContext.deleteSlot(name, adSlot);

    const currentSource = useMemo( () => getActiveSettings(name, SitesContext.state, [ROLLBACK_SETTINGS]), [name, SitesContext.state]);

    const localPlacements = useMemo(() => getLocalPlacements(PlacementsContext.placements, mergedSourceSlots), [mergedSourceSlots, PlacementsContext.placements]);
    
    ////// Hooks //

    useEffect(() => {
      let mergedSlot = mergeGlobalWithCurrentSource(
        currentSource,
        SitesContext.sites[globalIndex],
        SitesContext.sites
      );
      let mergedFrontPageAd = mergeFrontPageAdsGlobalWithCurrentSource(
        currentSource,
        SitesContext.sites[globalIndex],
        SitesContext.sites
      );

     const  sortedOnNumberId = mergedSlot.filter(item=> typeof item.slotPlacementId === 'number').sort((a, b) => (a.slotPlacementId > b.slotPlacementId) ? 1 : -1);
     const  sortedOnStringId = mergedSlot.filter(item=> typeof item.slotPlacementId === 'string').sort((a, b) => (a.slotPlacementId > b.slotPlacementId) ? 1 : -1);
     mergedSlot = sortedOnNumberId.concat(sortedOnStringId);

      setMergedSourceSlots(mergedSlot);
      let newCopiedSource = deepCopy(currentSource);
      newCopiedSource.slots = mergedSlot;
      newCopiedSource.frontpageadsPlaceholder = mergedFrontPageAd;
      setSiteSource(newCopiedSource);
    }, [SitesContext.state]); // eslint-disable-line react-hooks/exhaustive-deps

    ////// Render //

    const Slot = (settings, currentSite) => <AdSlot settings={settings}
                                     currentSite={currentSite}
                                     localPlacements={localPlacements}
                                     key={settings?.adSlotId || settings?.slotPlacementId || 'placeholder'}
                                     onUpdate={SitesContext.updateSlot(name)}
                                     highlightGlobal={!parseBool(siteSource.isGlobal)}
                                     onDeleteSlot={onDeleteSlot} /> 

    const AdSlots = props => {
       if (props.slots && props.slots.length > 0) {
          return props.slots.map((slot, idx) => {
           if (slot?.isEnable === 1 || !slot.hasOwnProperty("isEnable")) {
             return Slot(slot, props.currentSite);
           } else if (props.currentSite.isGlobal !== "false") {
             return Slot(slot, props.currentSite);
           }
           return [];
         });
       } else return [];
    }

    return(
        <>
            <Form className={styles.sitesetting__form}  validate={"true"}>
                <AuthBasedRender role={AuthRoles.Admin}>
                  <Metadata name={name} onMetadataChange={SitesContext.updateMetaData} settings={siteSource} />
                </AuthBasedRender>
                <div className={styles.sitesetting__wrapper}>
                <Collapsable title='Ad slots'>

                        <header className={styles['sitesetting--header']}>
                            <h4>Formats</h4>
                            <h4 className={`${styles.doubleGrow}`}>Sizes</h4>
                        </header>

                        <AdSlots slots={mergedSourceSlots} currentSite={siteSource} />
                        <AuthBasedRender role={AuthRoles.Admin}>
                          <Slot /> { /* Placeholder for adding a new slot */ }
                        </AuthBasedRender>
                        <AlertErrorExclamation predicate={hasError} message={NetworkContext.errorDesc} />

                </Collapsable>
                </div>
            </Form>
            <AuthBasedRender role={AuthRoles.Admin}>
              <div className={styles.sitesetting__footer}>
                  <div>
                      <FormButton onClick={SitesContext.undoChange(name)} disabled={siteHasNotUnsavedChanges(name, SitesContext.state, [])} label={'Undo'}/>
                      <FormButton onClick={SitesContext.saveChanges(name)} disabled={siteHasNotUnsavedChanges(name, SitesContext.state ) || hasTextFieldError(name, SitesContext.state )} label={'Save'}/>
                  </div>
                  <Changelog name={name} />
                  <ConfirmButton siteId={siteId} name={name}/>
              </div>
            </AuthBasedRender>

        </>
    )
}

const FormButton = React.memo(props => ( <Button className={styles.sitesetting__submit}  {...props}>{props.label}</Button> ));

export default React.memo(SiteSetting);

