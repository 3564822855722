import React, {useReducer, useEffect, useCallback} from "react";
import VerticalsContext from "./verticalsContext";
import VerticalsReducer from "./verticalsReducer";
import {useAxios} from "../../../hooks/useaxios";
import {PAGEVERTICALS_FETCH_COMPLETE, VERTICALS_FETCH_COMPLETE, VERTICALS_UPDATED} from "./types";
import {
    pick,
    is,
    clone,
    map,
    pipe,
    reject,
    isEmpty
} from "ramda";
import {isNotEmpty} from "../../../helpers/utils";
import {useIsLoggedIn} from "../../../hooks/useloginstate";


const initialState = {
    verticals: [],
    pageVerticals: {}
};

const cleanVerticals = pipe(pipe(map(pick(['vertical'])), reject(isEmpty)))

let updateRequest = data => ({
        method: 'POST',
        url: 'verticals',
        data: data,
        completeAction: VERTICALS_UPDATED
    })


const VerticalsModel = (props) => {

    const [state, dispatch] = useReducer(VerticalsReducer, initialState);

    const { setRequest } = useAxios(dispatch);




    const fetch = useCallback(async () => setRequest({ method: 'GET', url: 'verticals', completeAction: VERTICALS_FETCH_COMPLETE }), [setRequest]);

    const fetchPageVerticals = gamSiteId => {
        
        if (is(String, gamSiteId) === true ) {
            setRequest({ method: 'GET', url: `pageVerticals/${gamSiteId}`, completeAction: PAGEVERTICALS_FETCH_COMPLETE });
        }
    }

    const getPageVerticals = gamSiteId => 
    {
        return clone(state.pageVerticals[gamSiteId]) || []
    }

    const save = (verticals) => {
        let trimmedVerticals = cleanVerticals(verticals);
        if (isNotEmpty(trimmedVerticals)) {
            setRequest(updateRequest(trimmedVerticals))
        }
    }

    const {loggedIn} = useIsLoggedIn();

    useEffect(()=> {
        
        if (loggedIn) {
            fetch();
        }
    },[loggedIn]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <VerticalsContext.Provider value={{
            verticals: state.verticals,
            save: save,
            dispatch,
            fetchPageVerticals: fetchPageVerticals,
            pageVerticals: state.pageVerticals,
            getPageVerticals: getPageVerticals
        }}>
            {props.children}
        </VerticalsContext.Provider>
    );
};

export default VerticalsModel;