import React from 'react';
import {
    find,
    propEq,
    propOr,
    compose,
    equals,
    both,
    keys,
    isEmpty,
    is,
    prop,
    isNil,
    gt,
    pipe,
    adjust,
    match,
    has,
    ifElse,
    always,
    __, complement, sort, pathOr
} from 'ramda';
import {sizes} from "./sizes";

export const get = (target, path, defaultValue) => {
    const pathArray = Array.isArray(path) ? path : path.split('.').filter(key => key)
    const pathArrayFlat = pathArray.flatMap(part => typeof part === 'string' ? part.split('.') : part)

    return pathArrayFlat.reduce((obj, key) => obj && obj[key], target) || defaultValue
}


/**
 * If {slotPlacementId} is deemed being a label instead of id
 * it gets replaced with the corresponding ID, if found.
 * @param source {array} Data source
 * @param sourceKey {string} Key whose value we will compare
 * @param targetKey {string} Key whose value will be returned
 * @returns {function(*=): string || undefined}
 */
export const replacePlacementLabelWithId = (source, sourceKey, targetKey) => {
    return (slotPlacementId) =>
        is(String, slotPlacementId) && !is(Number, slotPlacementId) && !isNil(source) ?
        getPropFromListByKeyValue(source, sourceKey, slotPlacementId, targetKey) : slotPlacementId
};

export const getPropFromListByKeyValue = (list, targetKey, targetValue, targetProp, defaultValue) => {
    return compose(
        propOr(defaultValue, targetProp),
        find(propEq(targetKey, targetValue)))(list)
}

export const transformOffset = value => Math.max(value, 0);

export const transformLazyload = slot => () => {
    const desktop = parseInt(prop('offsetDesktop', slot), 10);
    const mobile = parseInt(prop('offsetDesktop', slot), 10);
    const result = hasSetOffset(desktop) || hasSetOffset(mobile);

    return !!result;
}

export const hasSetOffset = offset => !isNil(offset) && !isEmpty(offset) && is(Number, offset) && offset > 0;

export const sortStrings = sort((a, b) => a.localeCompare(b));

const p = (item, prop) => propOr('', `${prop}`, item);

export const sortByProp = prop  => (a, b) => p(a, prop).localeCompare(p(b, prop));

/**
 * Comparator function for sorting sizes in the form of (m)NNNxNNN
 * @type {Number}
 */
export const sortSizes = sort((a, b) => {
    const sizeA = sizes.parseSize(a)
    const sizeB = sizes.parseSize(b)
    return sizeA.width - sizeB.width
});

export const numberSort = sort((a, b) => a < b);

export const errorIf = (desc, predicate) => {
    if (predicate)
        console.error(desc)
}

export const isEmptyObject = both(is(Object), pipe(keys, isEmpty));

export const isNotEmptyObject = complement(isEmptyObject);

export const isNotNil = complement(isNil);

export const isNotEmpty = complement(isEmpty);

export const parseBool = b => !(/^(false|0)$/i).test(b) && !!b;

export const mightBeAnID = e => {
    return pipe(s => Number.parseInt(s),both(gt(__, -1), complement(equals(NaN))))(e)
}

export const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const first = target => {
    const [head] = target
    return head;
}

export const downloadFile = (data, filename) => {
    try {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    catch (error) {
        console.log(error);
    }
}

/**
 * Adjust a property at an object in a list indentified with a predicate
 * @params
 * @param targetProp {string} Target property
 * @param predicate {function(*): boolean} Predicate for target property
 * @param updateValue {string} new value
 */
export const adjustPropertyInList = (list, targetProp, predicate, updateValue) => {

    const index = list.findIndex(predicate );

    if (index >= 0) {
        const target = list[index];
        return adjust(index, () => ({...target, [targetProp]: updateValue}), list);
    }
}

export const getFilenameFromContentDispositionHeader =  pipe(
        ifElse(
            has('content-disposition'),
            pipe(
                prop('content-disposition'),
                match(/filename="(.*)"/),
                pathOr(undefined, [1])
            ),
            always(undefined)
        )
    )


/**
 * Identical to React.useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} [dependencies] - useEffect dependency list.
 */
export const useEffectExceptOnMount = (effect, dependencies) => {
    const mounted = React.useRef(false);
    React.useEffect(() => {
        if (mounted.current) {
            const unmount = effect();
            return () => unmount && unmount();
        } else {
            mounted.current = true;
        }
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset on unmount for the next mount.
    React.useEffect(() => {
        return () => mounted.current = false;
    }, []);
};

export const pluck = (arr, key) => arr.map(i => i[key]);

/**
 * Get height of the child (if any) for a ref
 */
export const firstChildHeight = ref => pathOr(0, ['current', 'children', 0, 'offsetHeight'], ref);

export const deepCopy = List => JSON.parse(JSON.stringify(List));
