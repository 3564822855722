import React, {useReducer, useContext, useEffect, useCallback} from "react";
import SitesContext from "./sitescontext";
import SitesReducer from "./sitesreducer";
import {DELETE_SLOT, GET_SITE, GET_SITES, SITE_UPDATED, UPDATE_SLOT} from "./types";
import {pipe, both, __} from 'ramda';
import {transformSiteSetting} from "../../../helpers/modelHelper";
import {getLatestHistoryState, history} from "../../../helpers/history";
import {selectSettingsFromAction} from "../../../helpers/actions";
import placementsContext from "../placement/placementscontext";
import sizesContext from "../sizes/sizescontext";
import toastContext from "../toast/toastcontext";
import authContext from "../auth/authcontext";
import {isNotEmpty, isNotNil} from "../../../helpers/utils";
import {useAxios} from "../../../hooks/useaxios";
import {placementIdByName} from "../../../helpers/placements";
import {useIsLoggedIn} from "../../../hooks/useloginstate";
import {updateMetadataAction} from "./sitesactions";


const initialState = {
    sites: [],
    siteData: [],
    updates: {},
    refresh: false,
    globalSlotIDs: [],
    request: undefined,
    updateDesc: ''
};

const getSubdomainSiteIds = (currentSite = {}, sites = []) => {
    const { gamSiteId } = currentSite;

    if (gamSiteId) {
        return sites.filter(
            site => site.isGlobal !== "true"
                && site.gamSiteId !== gamSiteId
                && site.gamSiteId.endsWith(gamSiteId)
        ).map(
            site => site.siteId
        );
    }

    return [];
}
const getParentSubdomainGamSiteId = (currentSite = {}, sites = []) => {
    const { gamSiteId } = currentSite;

    if (gamSiteId) {
        return sites.filter(
            site => site.isGlobal !== "true"
                && (site.gamSiteId === gamSiteId
                || site.gamSiteId.endsWith(gamSiteId))
        ).map(
            site => site.gamSiteId
        );
    }

    return [];
}
const getAllGamSiteId = (currentSite = {}, sites = []) => {
    const { gamSiteId } = currentSite;
    if (gamSiteId.includes("global")) {
        return sites.map(
            site => site.gamSiteId
        );
    }

    return [];
}

const parentalSiteIdForDomain = (domain, sites = []) => {
    const parts = domain.split('.');

    // Init parentSiteId: 0 for domains, and -1 for not found
    let parentSiteId = parts.length === 2 ? 0 : -1;

    const parentDomain = parts.length > 2 ? parts.slice(-2).join('.') : null;

    if (parentDomain) {
        parentSiteId = sites.find(site => site.gamSiteId === parentDomain)?.siteId || parentSiteId;
    }
    return parentSiteId;
}

const capitalizeFirst = str => str.charAt(0).toUpperCase() + str.slice(1)

const domainToName = domain => {

    const part = domain.split('.');
    return `${ capitalizeFirst( part[0] ) }${ (part.length > 2 ? `_${ part[1] }` : '') }`; 
}

const saveAvailable = both(isNotEmpty, isNotNil);

const SitesModel = (props) => {

    const [state, dispatch] = useReducer(SitesReducer, initialState);
    const { setRequest } = useAxios(dispatch);
    const PlacementsContext = useContext(placementsContext);
    const SizesContext = useContext(sizesContext);
    const ToastContext = useContext(toastContext);
    const AuthContext = useContext(authContext);

    const deleteSlot = useCallback( (siteName, adSlot) => dispatch({type: DELETE_SLOT, payload: {siteName, adSlot}}), []);

    const undoChange = useCallback( name => () => dispatch( history.getCurrentFallback(name)(state) ), [state]);

    const updateSlot = useCallback( siteName => adSlot => {
        dispatch({type: UPDATE_SLOT, payload: {siteName, adSlot, placements: PlacementsContext.placements}})
    }, [dispatch, PlacementsContext.placements]);

    const updateMetaData = useCallback( (siteName, prop, value) => {
        dispatch(updateMetadataAction(siteName, prop, value));
    }, [dispatch])

    //-------------------------------
    // Remote data
    //-------------------------------

    const fetchSites = useCallback(async () => setRequest({ method: 'GET', url: `site/${AuthContext.countryCode}`, completeAction: GET_SITES }), [setRequest]);
    const {loggedIn} = useIsLoggedIn()

    useEffect(()=> {

        if (loggedIn) {
            fetchSites();        
        }
    },[loggedIn])    // eslint-disable-line react-hooks/exhaustive-deps

    const createSite = useCallback( siteInfo => {
        const domain = siteInfo.domain
        setRequest({
            method: 'POST',
            url: 'site/',
            data: { gamSiteId: domain, name: domainToName(domain), parentSiteId: parentalSiteIdForDomain(domain, state.sites), countryCode: AuthContext.countryCode },
            completeAction: GET_SITE,
            onComplete: () =>  ToastContext.display(`${domain} created.`)
        });
    }, [setRequest, ToastContext, state.sites]);

    const deleteSite = useCallback(siteId => {
        const r = placementIdByName(PlacementsContext.placements)(siteId);
        setRequest({
            method: 'DELETE',
            url: `site/${siteId}`,
            onComplete: () => {
                fetchSites();
                ToastContext.display(`Site ${r} is deleted.`);
            }
        })


    }, [setRequest, ToastContext, PlacementsContext.placements, fetchSites]);

    // FIXME: This is unfortunately a very tight coupling
    const onSaveComplete = useCallback((name, gamSiteIds, shouldRefetch) => () =>  {

        setRequest({
            method: 'POST',
            url: `fallback`,
            data: { gamSiteIds },
        });

        if (shouldRefetch) {
            fetchSites();
            PlacementsContext.getPlacements();
            SizesContext.fetchSizes();
        }
        ToastContext.display(`${name} updated successfully.`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSites, ToastContext, SizesContext, PlacementsContext]);

    /**
     * Set a request to send the latest history object to the API
     */
    const saveChanges = useCallback(name => () => {
        const action = getLatestHistoryState(name)(state);
        if ( saveAvailable(action)) {
            const data = pipe(selectSettingsFromAction, transformSiteSetting(__, PlacementsContext.placements))(action);
            const subdomainSiteIds = getSubdomainSiteIds(data, state.sites);
            const shouldRefetch = data?.isGlobal === "true" || subdomainSiteIds.length > 0;
            const gamSiteIds = data?.isGlobal === "true" ? getAllGamSiteId(data, state.sites) : getParentSubdomainGamSiteId(data, state.sites);
            setRequest({
                method: 'PUT',
                url: `site/${data.siteId}`,
                data: { ...data, subdomainSiteIds },
                completePayload: { appliedAction: action },
                completeAction: SITE_UPDATED,
                onComplete: onSaveComplete(name, gamSiteIds, shouldRefetch),
            });
        }
    }, [state, PlacementsContext.placements, onSaveComplete, setRequest]);

    return (
        <SitesContext.Provider value={{
            state,
            initialFetch: state.initialFetch,
            sites: state.sites,
            updates: state.updates,
            updateDesc: state.updateDesc,
            updateMetaData,
            dispatch,
            createSite,
            fetchSites,
            updateSlot,
            deleteSlot,
            deleteSite: deleteSite,
            saveChanges,
            undoChange,
        }}>
            {props.children}
        </SitesContext.Provider>
    );
};

export default SitesModel;
