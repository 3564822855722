import {
    toPlacementObject,
} from "../../../helpers/placements";

import {ADD_PLACEMENT, GET_PLACEMENTS} from "./types";

export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_PLACEMENTS:
            return {
                ...state,
                lookup: toPlacementObject(payload.data),
                placements: [...payload.data],
            };
        case ADD_PLACEMENT:


            const placements = [ ...state.placements, payload.data];
            const lookup = toPlacementObject(placements);




        const r = {
        ...state,
            placements: placements,
            lookup: { ...state.lookup, ...lookup}
        };


          return r;

        default:
            return state;
    }
};
