import {pluck, without, prop, has, is, pipe, objOf, applySpec, startsWith, always, toString, split, toLower, ifElse, filter} from "ramda";
import {sortSizes} from "./utils";

export const trimSizes = ifElse(has('length'), pluck('adSlotSize'), always([]))

export const isOptionNotRemoved = opt => typeof opt === "string" && !opt.startsWith("r");

/**
 * Remove {local} sizes from {global} so that every ad can display unique settings
 * @param global {array} All sizes
 * @param local {array} Sizes for a specific ad
 * @returns {array}
 */
export const uniqueLocalSizes = (global, local) => {
    global = trimSizes(global);
    local = trimSizes(local);

    return pipe(without(local), sortSizes, filter(isOptionNotRemoved))(global) || [];
}

export const transformSizeObject_toTypeahead = size => {

    if (is(String, size)) {
        return { 'adSlotSize': size.toLowerCase() };
    }

    if (has('customOption', size)) {
        return pipe(prop('label'), toLower, objOf('adSlotSize'))(size);
    }

    if (is(Object, size)) {
        return size;
    }

    return {};
}

const isMobile = pipe(toLower, startsWith('m'), toString);

/**
 * Convert a size to object
 * @param size {string} in the form of (optional M)NNNxNNN
 * @returns {object} { width: x, height: x}
 */
const components = (size) =>
    pipe(
        toLower,
        split('x'),
        applySpec(
        {
            width: v => v[0],
            height: v=> v[1]
        })
        )(size);


/**
 * Parse adsize in the form of width, height, mobileSize
 * @param size {string} in the form of (optional m for mobile)NNNxNNN
 * @returns {object}
 */
const parseSize = size => {
    const mobileSize = isMobile(size);
    const { width, height } = components(size);
    return { size, mobileSize, width, height }
}


/**
 * Validate size
 * @param size (string}
 * @returns {boolean}
 */
const validateSize = size => /^[m]?\d{1,4}[x]\d{1,4}$/gi.test(size)

/**
 * Callback for size input
 */
const allowNewSize = (result, value) => validateSize(value.text)

export const sizes = {
    parseSize,
    validateSize,
    allowNewSize
}