import React, {useEffect, useState} from 'react';
import { is } from "ramda";
import AuthContext from "./authcontext";

export const AuthBasedRender = ({ role, children, isVisible, title }) => {
    const context = React.useContext(AuthContext);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect( () => {
        const level = !is(Number, role) ? false : context.role >= role
        setIsAuthorized(level);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.role])

    return (
    <>
        { isAuthorized && children}
    </>
    )
}

