/**
 * Mapped site settings for text input values
 */
const metaDefinitions = [
    {
        type: 'input',
        displayName: 'Lazyload',
        skipGlobal: true,
        children: [
            {
                prop: 'fetchMarginPercentDesk',
                label: "Fetch margin %",
                desc: 'Offset from viewport when ad is fetched and rendered for desktop.',
                headline: 'Desktop'
            },
            {
                prop: 'renderMarginPercentDesk',
                label: "Render margin %",
            },
            {
                prop: 'fetchMarginPercentMob',
                label: "Fetch margin %",
                desc: 'Offset from viewport when ad is fetched and rendered for mobile.',
                headline: 'Mobile'
            },
            {
                prop: 'renderMarginPercentMob',
                label: "Render margin %",
            },
        ]
    },
    {
        type: 'input',
        displayName: 'Video',
        path: 'video',
        skipGlobal: true,
        children: [ 'clickToPlay', 'autoPlay']
    },
    {
        type: 'input',
        displayName: 'External resources',
        children: [ 'demandManagerUrl']
    },
    {
        type: 'input',
        displayName: 'Ad Watch',
        path: 'statscollector',
        children: [
            {
                prop: 'samplerate',
                label: 'Sample rate',
                pattern: /^[0-9]+$/
            }
        ]
    },
    {
        type: 'input',
        displayName: 'Intext',
        path: 'intext',
        children: [
            {
                prop: 'textBeforeDesk',
                label: "Text added until new Ad",
                desc: `Minimum added text until new Ad is shown, or text at the end of the content.
                There are 4 criteria to fulfil until a new ad will be injected: 
                *** A - Enough characters (images counted are set below as Char Per Image) since last injected ad [Text added until new Ad]
                *** B - Enough characters at the very last paragraph of the article [Text at the end]
                *** C - Enough characters between ad and image placed before (above) [Image before]
                *** D - Enough characters between ad and image placed after (below) [Image after]`,
                headline: 'Text - Desktop'
            },
            {
                prop: 'textAtTheEndDesk',
                label: "Text at the end",
            },
            {
                prop: 'textBeforeMob',
                label: "Text added until new Ad",
                desc: 'Minimum added text until new Ad is shown, or text at the end of the content.',
                headline: 'Text - Mobile'
            },
            {
                prop: 'textAtTheEndMob',
                label: "Text at the end",
            },
            {
                prop: 'imageHeavyCharPerImage',
                label: 'Characters per image',
                desc: `Rules applied for images, when article has mostly images. 
                    We can inject intext between images and ignore (B,C,D),
                    so that intext can be placed close to an image.`,
                headline: "Images - Image Heavy articles",
            },
            {
                prop: 'charPerImage',
                label: 'Characters per image',
                desc: `Rules applied for images, when article has more text than images. As of today we decide to inject Ads not too close to images,
                    and never between images that comes in a row`,
                headline: "Images - Text Heavy articles",
            },
            {
                prop: 'imageBeforeDesk',
                label: "Image before",
                desc: 'Minimum text between an Ad and image (Only for Text Heavy articles)',
                headline: "Images - desktop",
            },
            {
                prop: 'imageAfterDesk',
                label: "Image after",
            },
            {
                prop: 'imageBeforeMob',
                label: "Image before",
                desc: 'Minimum text between an Ad and image (Only for Text Heavy articles)',
                headline: "Images - Mobile",
            },
            {
                prop: 'imageAfterMob',
                label: "Image after",
            },
        ]
    },
    {
        type: 'input',
        displayName: 'FrontPageAds',
        path: 'frontPageAd',
        children: [
            {
                prop: 'heightBeforeDesk',
                label: 'Minimum height (px) between Ads Desk',
                desc: `DESKTOP`,
                headline: "Front / Category-pages: Browser Rendering of Heights are used when calculating next Ad injection",
            },
            {
                prop: 'heightBeforeMob',
                label: 'Minimum height (px) between Ads Mob',
                desc: `MOBILE`,
            },
            {
                prop: 'startMediaCount',
                label: 'Start height',
                desc: `Start height that is used when content is summed up`,
                headline: "Front / Category-pages: Assumptions on heights are used when calculating next Ad injection",
            }
        ]
    },
    {
        type: 'input',
        displayName: 'Advanced Frontpageads',
        path: 'frontPageAd',
        children: [
            {
                prop: 'textBeforeDesk',
                label: "Minimum Height added until new Ad",
                headline: 'Height Units for Content on DESKTOP'
            },
            {
                prop: 'oneTeaserDesk',
                label: "1 Teaser in a Row Desk",
            },
            {
                prop: 'twoTeasersDesk',
                label: "2 Teasers in a group Desk",
            },
            {
                prop: 'threeTeasersDesk',
                label: "3 Teasers in a group Desk",
            },
            {
                prop: 'fourTeasersDesk',
                label: "4 Teasers in a group Desk",
            },
            {
                prop: 'affiliateGalleryDesk',
                label: "AffiliateGallery Desk",
            },
            {
                prop: 'newsLetterSignupDesk',
                label: "Newsletter Signup Desk",
            },
            {
                prop: 'mostReadDesk',
                label: "Most Read Desk",
            },
            {
                prop: 'blogSliderDesk',
                label: "Blog Slider Desk",
            },
            {
                prop: 'textBeforeMob',
                label: "Minimum Height added until new Ad",
                headline: "Height Units for Content on MOBILE",
            },
            {
                prop: 'oneTeaserMob',
                label: "1 Teaser in a group Mob",
            },
            {
                prop: 'twoTeasersMob',
                label: "2 Teasers in a group Mob",
            },
            {
                prop: 'threeTeasersMob',
                label: "3 Teasers in a group Mob",
            },
            {
                prop: 'fourTeasersMob',
                label: "4 Teasers in a group Mob",
            },
            {
                prop: 'affiliateGalleryMob',
                label: "AffiliateGallery Mob",
            },
            {
                prop: 'newsLetterSignupMob',
                label: "Newsletter Signup Mob",
            },
            {
                prop: 'mostReadMob',
                label: "Most Read Mob",
            },
            {
                prop: 'blogSliderMob',
                label: "Blog Slider Mob",
            },
        ]
    }    
];

export default metaDefinitions;