import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import UserProfile from '../userprofile/userprofile';
import logo from "../../assets/aller_media.png";
import styles from './header.module.scss';
import React from "react";
import { AuthBasedRender } from "../stores/auth/authbasedrender";
import { AuthRoles } from "../stores/auth/authRole";

const Header = props => {
    return (
        <header className="App-header">
            <Navbar className={styles.navbar} expand="lg">
                <Navbar.Brand href="/">
                    <img src={logo} alt={'Logotype'} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey={window.location.pathname} className="mr-auto">
                      <Nav.Link href="/settings">Ad settings</Nav.Link>
                      <AuthBasedRender role={AuthRoles.Admin}>
                        <Nav.Link href="/ADS">Authorized digital sellers</Nav.Link>
                      </AuthBasedRender>
                      <Nav.Link href="/options">Options</Nav.Link>
                    </Nav>
                    <UserProfile />
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default Header;