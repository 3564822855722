import React, { useRef, useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import styles from './ads-txt.module.scss'
import {useAxios} from "../../hooks/useaxios";
import {pipe, both, is, isEmpty, complement, identity, pathOr, propOr} from 'ramda';
import { useContext } from 'react';
import authContext from "../stores/auth/authcontext";

import {Alert} from 'react-bootstrap';
import {useOnClickOutside} from "../../hooks/useonclickoutside";

const reqParams = (url, method, data = {}) => ({
    url,
    method,
    data,
    parseResponse: pipe(propOr(identity, 'data')),
})

const isNotEmpty = complement(isEmpty);

const validResponse = both(complement(isEmpty), is(Object))

const resolveContentFromRef = pathOr('', ['current', 'value']);

// @TODO: How do we actually validate this?
const validate = pipe(resolveContentFromRef, isNotEmpty);



export const Adstxt = () => {

    const [rows, setRows] = useState();
    const [valid, setValid] = useState(false);
    const [shouldDisplayError, setShouldDisplayError] = useState(false);
    const { data, setRequest } = useAxios();
    const ref = useRef();
    const wrapperRef = useRef();
    const AuthContext = useContext(authContext);

    useOnClickOutside(wrapperRef, () => setShouldDisplayError(false));

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const options = reqParams(`/ADS/${data.ID}`, 'PUT', { ...data, rows });
        setRequest(options);
    }

    useEffect(() => {
        const options = reqParams(`/ADS/${AuthContext.countryCode}?format=json`, 'GET')
        setRequest(options);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (validResponse(data)) {
            setRows(data.rows);
        }
    }, [data])

    const onBlur = e => {
        setValid(validate(ref));
        setShouldDisplayError(true);
    }

    const onChange = e => {
        setRows(e.currentTarget.value)
    }

    return (
        <>

            <div ref={wrapperRef} className={styles.adstxt_wrapper}>
            <h2>Authorized digital sellers</h2>
                <Form onSubmit={onSubmit}>
                    <Form.Group className={styles.input_row} controlId="fsdfds">
                        <Form.Label>This list is applied to all sites</Form.Label>
                        <Form.Control onBlur={onBlur} ref={ref} as="textarea" value={rows || ''} onChange={onChange} rows={3} />
                    </Form.Group>

                    {
                        (!valid && shouldDisplayError) && <Alert variant={'danger'}>Error: Invalid input, follow
                            <Alert.Link href={"https://iabtechlab.com/wp-content/uploads/2019/03/IAB-OpenRTB-Ads.txt-Public-Spec-1.0.2.pdf"}> IAB ads.txt specification.</Alert.Link>
                        </Alert>

                    }


                    <div className={styles.form_button_wrapper}>
                        <button disabled="" label="Save" type="submit" className="btn btn-primary">Save</button>
                    </div>
            </Form>

        </div>
            </>
    )
}