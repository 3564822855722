import React, { useReducer, useEffect, useState, useMemo, useCallback } from "react";
import PlacementsContext from "./placementscontext";
import PlacementsReducer from "./placementsreducer";
import {ADD_PLACEMENT, GET_PLACEMENTS} from "./types";
import instance from "../../../axios";

import {isNotPlacementByName, placementIdByName, placementNameByID, toPlacementObject} from "../../../helpers/placements";
import {mightBeAnID} from "../../../helpers/utils";
import {validate} from "../../../helpers/validate";


const Placements = (props) => {
    let initialState = {
        placements: [],
        lookup: {}}

    const [state, dispatch] = useReducer(PlacementsReducer, initialState);
    const [request, setRequest] = useState()

    const getPlacements = () => setRequest({method: 'get', url: 'placement/all', completeAction: GET_PLACEMENTS})
    const addPlacement = (adSlotName) => setRequest({method: 'post', url: 'placement/', data: {adSlotName}, completeAction: ADD_PLACEMENT})

    useEffect(() => {
        getPlacements();
    }, [])

    useEffect(() => {
        getPlacements();
    }, [state.addedFormat])

    useEffect(() => {
        executeRequest(request)
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps


    const executeRequest = useMemo(() => async (request) => {
        if (request) {
            let res = await instance({ ...request});
            if(res){
                let { data } = res;

                dispatch({ type: request.completeAction, payload: data });    
            }
        }
    }, [])

    const isValidNewPlacement = val => validate.isPlacementName(val) && isNotPlacementByName(val)(state.placements);

    const createPlacement = name => {
        if (isValidNewPlacement(name)) {
            addPlacement(name);
        }
    }

    const toObject = useMemo(() =>  () => {
       return toPlacementObject(state.placements);
    }, [state.placements]);

    const getNameFromID = useMemo(() => ID => {
        return mightBeAnID(ID) ? [placementNameByID(state.placements, ID) || 'unknown'] : undefined
    }, [state.placements])

    const getIDfromName = useCallback( name => placementIdByName(name)(state.placements), [state.placements]);

    return (
        <PlacementsContext.Provider
            value={{
                placements: state.placements,
                addedFormat: state.addedFormat,
                placementNames: state.placementNames,
                lookup: state.lookup,
                getPlacements,
                addPlacement,
                getNameFromID,
                getIDfromName,
                createPlacement,
                toObject
            }}
        >
            {props.children}
        </PlacementsContext.Provider>
    );
};

export default Placements