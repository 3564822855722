import React, {useState} from "react";
import { Form, Col } from "react-bootstrap";
import styles from "./inputswrapper.module.scss";
import {SettingsHeadline} from "../containers/dynamicSettings/dynamicSettings";



const shouldRenderEveryLabel = (label, type) => label && type === SettingsHeadline.EVERY;
const shouldRenderLeadingLabel = (label, type, index) => label && type === SettingsHeadline.LEADING && index === 0;


const isValidInput = pattern => value => pattern ? new RegExp(pattern).test(value) : true

const InputsWrapper =React.forwardRef((
{   disabled,
    size,
    label,
    prop,
    value,
    pattern,
    autoincrement,
    rowIndex,
    headlineType = SettingsHeadline.EVERY} ,ref) => {

    const validate = isValidInput(pattern)
    const [userInput, setUserInput] = useState(value ?? '');
    const [isValid, setIsValid] = useState(validate(userInput))



    const onChange = (event) => {
        const inputVal = event.target.value;
        const valid = validate(inputVal)

        setIsValid(valid)
        setUserInput(event.target.value)
    }

    return (
    <>
        {
            shouldRenderEveryLabel(label, headlineType) &&
            <Col xs={label.size}>
                <Form.Label>{label.desc}</Form.Label>
            </Col>
        }
        <Col className={styles.input_wrapper} md={size}>
            {
                shouldRenderLeadingLabel(label, headlineType, rowIndex) &&
                <Form.Label className={styles.input_label__leading}>{label.desc}</Form.Label>
            }

            <Form.Control
                ref={ref}
                type={ disabled ? 'hidden' : 'text' }
                placeholder={''}
                value={autoincrement ? autoincrement(rowIndex) : userInput}
                name={prop}
                data-order={rowIndex}
                data-valid={isValid}
                onChange={onChange}
                disabled={ autoincrement ? true : false }
            />
        </Col>
    </>
    )
});

export default InputsWrapper;

