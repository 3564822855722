import {
    __,
    always,
    and,
    append,
    apply,
    cond,
    dissoc,
    gt,
    head,
    ifElse,
    difference,
    is,
    isNil,
    keys,
    length,
    lt,
    map,
    both,
    reverse,
    pathOr,
    pipe,
    identity,
    prop,
    propEq,
    propOr,
    symmetricDifference,
    T,
    values,
    addIndex,
    reduce, mapObjIndexed
} from "ramda";
import objectDiff from "./objectDif";
import forEachObjIndexed from "ramda/es/forEachObjIndexed";
import applySpec from "ramda/es/applySpec";

const parseSettingsObj = settings => ({ slots: propOr([], 'slots', settings), settings: dissoc('slots', settings) });

const isExepectedLength = propEq('length', 2)



const updatedIsArray = updatedValues => and(isArray(updatedValues[0]), isArray(updatedValues[1])) && isExepectedLength(updatedValues);

const getAffectedPropFromDiff = propKey => pipe(apply(symmetricDifference), head, prop(propKey));

const getActionForUpdatedArrays = pipe(
    map(length),
    cond([
        [apply(gt, __), always(`removed`)],
        [apply(lt, __), always(`added`)],
        [T, always('done nothing')]])
);

const arrayActionDesc = values => ({ verb: getActionForUpdatedArrays(values), targetValue: getAffectedPropFromDiff('adSlotSize')(values) });

const genericActionDesc = values => {



    return ({verb: 'changed', targetValue: values[1]})
}

const updatedPropKey = pipe(keys, head)

const updatedPropsValue = pipe(values, head);

const parseLogObject = log => ({ index: updatedPropKey(log), value: updatedPropsValue(log) });

/**
 * Resolves the description object for a diff that is a newly added property
 */
const resolveAddedPropertyDesc = obj => ({ key: updatedPropKey(obj), targetValue: updatedPropsValue(obj), verb: 'added new' });

const resolveChangedValueDesc = (key, val) => {
    const desc = cond([
        [updatedIsArray, arrayActionDesc],
        [T, genericActionDesc]
    ]);
    return { ...desc(val), key }; // We might exchange key for something else (like slotPlacementId to placement)
}

const resolveDeletedSlotDesc =  (target) => {






    const targetValue =  placementIdForDiff(target);
    const verb = 'deleted';
    const key = 'slot';

    return {targetValue, verb, key};
}

const resolveAddedSlotDesc = target => {





    const targetValue =  placementIdForDiff(reverse(target));
    const verb = 'added';
    const key = 'slot';

    return {targetValue, verb, key};
}

const isArray = Array.isArray || function(val) { return val instanceof Array; };

const filterSlots = i => pipe(pathOr([], [i, 'slots']), map(dissoc('lazyload')));

const parseSlots = data => [ filterSlots(1)(data), filterSlots(0)(data) ];

const parseSettings = data => [
    pathOr(undefined, [0, 'settings'])(data),
    pathOr(undefined, [1, 'settings'])(data)
        ]



/**
 * Resolves the update description and appends it to the {list} if successful
 */
const updateLog = (resolveFunc, index, log) => {
    log = log || [];
    return pipe(
        apply(resolveFunc),
        ifElse(
            isNil,
            always(log),
            pipe( applySpec({
                    index: always(index),
                    value: identity }),
                    append(__, log))
        )
    )
}

const isNewPlacement = val => is(Array, val) && isNil(val[1]);  // TODO: Should really look at the key name as well

const placementIdForDiff = pipe(
    apply(difference, __),
    ifElse(
        propEq('length', 1),
        pipe(head, propOr(undefined, 'slotPlacementId')),
        always(undefined) ));

const arrayIsShorter = (target, compare) => target < compare;

const isDeletedSlot = (slots) => arrayIsShorter(slots[1], slots[0])

const isAddedSlot = (slots) => arrayIsShorter(slots[0], slots[1])

const buildMetaSettingsLog = pipe(
    mapObjIndexed(
        (value, key, object) => ({
                value:   {
                    verb: 'changed',
                    key,
                    targetValue: propOr(undefined, '0', value)
                }
            })),
        values
);

const buildSlotsLog = (source, difference) => {

    let logs = [];

    if (isAddedSlot(source)) {

        logs = updateLog(resolveAddedSlotDesc, undefined, logs)([source])

    } else if (isDeletedSlot(source)) {
        logs = updateLog(resolveDeletedSlotDesc, undefined, logs)([source])
    }
    else {

        forEachObjIndexed((value, index)  => {
                if (isNewPlacement(value)) {

                    console.log('new placements')
                    logs = updateLog(resolveAddedPropertyDesc, index, logs)([value[0]])

                }
                else {
                    forEachObjIndexed((innerVal, innerKey) => {

                        logs = updateLog(resolveChangedValueDesc, index, logs)([innerKey, innerVal])

                    }, value)
                }},
            difference);
    }

    return logs;
}


/**
 * Build a difference log with an array containing two states [{new}, {previous}]
 * The difference for an object have two different structures, depending on the source value:
 * [ {new value}, {prev value} ] or { updatedKey: [{new value}, {prev value}] etc... }
 */
const build = (...theArgs) => {
    const compare = theArgs.map(parseSettingsObj);

    const settings = parseSettings(compare);
    const settingsDiff = apply(objectDiff, settings);

    const slots = parseSlots(compare);
    const slotsDiff = apply(objectDiff, slots);

    let slotsLog = buildSlotsLog(slots, slotsDiff);
    let settingsLog = buildMetaSettingsLog(settingsDiff)

    return both(is(Array, slotsLog), is(Array, settingsLog) ) ?
        [ ...slotsLog, ...settingsLog] : [];
}

/**
 * Build a difference log from a list with 2 or more previous updates
 */
const buildFromList = list => {

    const indexedReducer = addIndex(reduce);
    const diffLog = indexedReducer((acc, item, i, list) => {

        if ( (i + 1) < list.length) {
            const log = head(changeLog.build(list[i+1], list[i]));
            acc.push(log);
        }

        return acc
    }, [], list)

    return diffLog;

}

export const changeLog = {
    build,
    buildFromList,
    parseLogObject
}

