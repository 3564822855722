import React, { useState, useMemo } from 'react';
import CollapseButton from "../../ui/collapsebutton/collapsebutton";
import Collapse from 'react-bootstrap/Collapse';
import Tab from 'react-bootstrap/Tab';
import styles from "./siteTabs.module.scss";
import SiteName from "../../sitename/sitename";
import TabTitleBar from "../../tabtitlebar/tabtitlebar";
import SiteSetting from "../../sitesetting/sitesetting";
import SettingsVerticals from "../../containers/settingsVerticals/settingsVerticals";
import { isGlobalSite, isSubdomain, isSubSite } from "../../../helpers/sites";
import { AuthBasedRender } from "../../stores/auth/authbasedrender";
import { AuthRoles } from "../../stores/auth/authRole";
import AuthContext from "../../stores/auth/authcontext";
import DynamicSettings from '../dynamicSettings/dynamicSettings.jsx';
import { ADS_BUNDLE_VERSION } from "../../dynamic/adsBundleVersion";
import { GROWTHBOOK } from '../../dynamic/growthbook';
import SiteCache from "../siteCache/siteCache";


const resolveSiteClassName = settings => `${styles.tabsetting} 
${isGlobalSite(settings) ? styles.tabsetting__global : ""}
    ${isSubSite(settings) ? styles.tabsetting__child : ""}
    ${isSubdomain(settings) ? styles.tabsetting__child : ""}
    ${!isSubdomain(settings) && !isGlobalSite(settings) ? styles.tabsetting__site : ""}
    `;

const tabTitleName = {
    'verticals': 'Verticals for pageslugs',
    'growthbook-config': 'Client AB-test',
    'ads-bundle-version': 'Ads Bundle Version',
    'cache': 'Cache Handling',
}

function SiteTab(props) {
    const { site, opened, setOpen } = props;
    const { gamSiteId, siteId } = site;
    const authContext = React.useContext(AuthContext);
    const collapsed = gamSiteId === opened
    const [key, setKey] = useState(authContext.role === AuthRoles.Admin ? 'ads' : 'verticals')

    const adsTitle = isGlobalSite(site) ? 'Global settings' : 'Local settings'

    const toggle = () => {
        setOpen(collapsed ? undefined : gamSiteId)
    }


    const tabTitle = useMemo(() => {
        return (key === 'ads') ? adsTitle : tabTitleName[key];
    }, [key, adsTitle]);

    return (
        <Tab.Container id="left-tabs-example" activeKey={key} onSelect={setKey}>
            <div className={resolveSiteClassName(site)}>
                <div className={styles.tabsetting__consistent}>
                    <SiteName name={site.name} gamSiteId={gamSiteId} />
                    <CollapseButton autoScrollTop onToggle={toggle} collapsed={collapsed} aria-controls="site-settings" />
                </div>

                <Collapse in={collapsed}>

                    <div>

                        {collapsed &&
                            <>
                                <TabTitleBar title={`${tabTitle}`} />
                                <Tab.Content>
                                    <AuthBasedRender role={AuthRoles.Editor}>
                                        <Tab.Pane eventKey="ads">
                                            <SiteSetting site={site} />
                                        </Tab.Pane>
                                    </AuthBasedRender>
                                    <Tab.Pane eventKey="verticals">
                                        <SettingsVerticals gamSiteId={props.site.gamSiteId} />
                                    </Tab.Pane>
                                    <AuthBasedRender role={AuthRoles.Analytics}>
                                        <Tab.Pane eventKey="growthbook-config">
                                            <DynamicSettings siteId={siteId}
                                                rowStructure={GROWTHBOOK.rowStructure}
                                                controls={GROWTHBOOK.controls}
                                                settings={GROWTHBOOK.settings}
                                                gamSiteId={props.site.gamSiteId} />
                                        </Tab.Pane>
                                    </AuthBasedRender>
                                    <AuthBasedRender role={AuthRoles.Admin}>
                                        <Tab.Pane eventKey="ads-bundle-version">
                                            {/* <SettingsAdsBundleVersion gamSiteId={props.site.gamSiteId} /> */}
                                            <DynamicSettings siteId={siteId}
                                                rowStructure={ADS_BUNDLE_VERSION.rowStructure}
                                                controls={ADS_BUNDLE_VERSION.controls}
                                                settings={ADS_BUNDLE_VERSION.settings}
                                                gamSiteId={props.site.gamSiteId} />
                                        </Tab.Pane>
                                    </AuthBasedRender>
                                    <AuthBasedRender role={AuthRoles.Admin}>
                                        <Tab.Pane eventKey="cache">
                                            <SiteCache gamSiteId={props.site.gamSiteId} />
                                        </Tab.Pane>
                                    </AuthBasedRender>
                                </Tab.Content>
                            </>

                        }
                    </div>

                </Collapse>
            </div>
        </Tab.Container>
    )
}

export default React.memo(SiteTab);