import { useEffect, useContext, useState } from "react";

import authContext from "../components/stores/auth/authcontext";

export const useIsLoggedIn = () => {

    const AuthContext = useContext(authContext);
    const [loggedIn, setLoggedIn] = useState( AuthContext.name !== '' ); 

    useEffect(() => {

        setLoggedIn(AuthContext.name !== '')
    }, [AuthContext.name]);

    return {loggedIn};
}
