import axios from 'axios';
import getEnvParam, {EnvParams} from "./environment";

const instance = axios.create({
    baseURL: getEnvParam(EnvParams.BASE_URL),
});
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};

export default instance;
