import {SettingsHeadline} from "../containers/dynamicSettings/dynamicSettings";

const isCharDigit = n => n < 10;

const alphaVal = (s) => {
	const t = s.toString();
	return isCharDigit(t) ? String.fromCharCode(97 + s)  : t.toLowerCase().charCodeAt(0) - 97 + 1;
}

const type = {
	display: 'display',
	video: 'video'
}

const controls = {
	submit: {
		label: 'Save'
	},
	options: [
		{
			onClickParams: [type.display],
			label: 'Add display',
		},
		{
			onClickParams: [type.video],
			label: 'Add video'
		}
	]
}

const rowStructure = [
	{
		prop: "version",
		autoincrement: alphaVal,
		label: {
			desc: 'Version',
			size: 'auto'
		},
		value: "",
		size: 1
	},
	{
		prop: "experimentId",
		label: {
			desc: 'Experiment ID',
			size: 'auto'
		},
		value: "",
        pattern: /[a-z0-9-_]{3,}:\d{1}/,
		size: 8
	},
	{
		disabled: true,
		prop: "type",
		value: "display"
	}
]

const settings = {
	headlineType: SettingsHeadline.LEADING,
	endpoint: 'growthbook',
	limit: 5,
	description: 'Add Growthbook experiment id\'s in the pattern of id:variant',
	toastMessage: 'Growthbook experiment has been saved',
	inputs: 'multiple',
	groupBy: trg => trg[2].value, // TODO: Could be better!
	prepRequest: (rows, additional) => rows.map( row => ({ ...row, ...additional}))

}

export const GROWTHBOOK = {
	controls,
	type,
	rowStructure,
	settings
}