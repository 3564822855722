import {__, always, append, either, head, ifElse, isEmpty, isNil, propOr, pipe, test, unary, identity} from "ramda";

const onlyDigits = test(/^[0-9]+$/);

const toSingle = value => pipe(head, propOr(head(value), 'label'))(value);

/**
 * Extract the value from a typeahead selected property.
 * @param val {any}
 * @returns {string|number}
 */
const toValue = pipe(toSingle, ifElse(onlyDigits, unary(parseInt), identity));

/**
 * TODO: Legacy!
 * Wrap value in an array to support a typeahead select component
 * @returns {}
 */
const _toTypeahead = val => isNil(val) ? [] : [val];

/**
 * Wrap value in an array to support a typeahead select component
 */
const toTypeahead = ifElse(
    either(isNil, isEmpty),
    always([]),
    append(__, [])
)

const headToInt = pipe(head, unary(parseInt));

/**
 * Format a selected value from typeahead to an integer
 * @type {int or NaN}
 */
const toInt = either( headToInt, head);

export const typeahead = {
    toValue,
    _toTypeahead,
    toTypeahead,
    toInt
}


