import React, {useState, useContext} from 'react';
import sitesContext from "../stores/sites/sitescontext";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import styles from "./confirmButton.module.scss";

const ConfirmButton = props => {

    const SitesContext = useContext(sitesContext);
    const {name, siteId} = props;


    const onDeleteSite = e => {
        e.preventDefault();
        e.stopPropagation();
        SitesContext.deleteSite(siteId);

        handleClose();
    }

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClose = () => setShowConfirmation(false);

    const handleClick = e => {
        e.preventDefault();
        setShowConfirmation(true);
    }

    return (
        <>
            <a className={styles.delete_button} href='/#' onClick={handleClick}>Delete Site</a>

            <Modal show={showConfirmation} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {name}?</p>
                    <p>This action cannot be undone.</p>
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={onDeleteSite}>
                        Confirm Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default ConfirmButton;