import {VERSION_FETCH_COMPLETE, VERSION_UPDATED, VERSION_FETCH_BY_GAMSITE_ID} from "./types";
import {
    pluck,
    clone,
    pathOr,
    propOr,
    is,
    gt,
    head,
    both,
    __,
    always,
    ifElse,
    pipe,

} from "ramda";
import {isNotNil} from "../../../helpers/utils";

const arrayHasItems = pipe(propOr(0, 'length'), gt(__, 0));

const dataFromResponse = pipe(pathOr(undefined, ['response', 'data']), clone);

const expectedResponse = both( is(Array), arrayHasItems);

const getFirstGamSiteId = pipe(pluck('gamSiteId'), head);

const pickGamSiteFromResponse = ifElse(expectedResponse, getFirstGamSiteId, always(undefined));


export default (state, action) => {
    const { payload, type } = action;

    switch (type) {

        case VERSION_FETCH_COMPLETE:
        case VERSION_UPDATED:
            
            return { ...state, bundleVersion: [ ...action.payload.response.data ]};

        case VERSION_FETCH_BY_GAMSITE_ID:

                const data = dataFromResponse(payload);
                const gamSiteId = pickGamSiteFromResponse(data);
            
                const r = both(isNotNil(gamSiteId), is(String, gamSiteId)) ?
                    { ...state, sitesConfig: { ...state.sitesConfig, [gamSiteId]: payload.response.data }} :
                    state;
    
                return r;

        default:
            return state;
    }
};
