import React, { useEffect, useRef, useContext, useState } from "react";
import styles from "./dynamicSettings.module.scss";
import { Form, Row, Container } from "react-bootstrap";
import InputsWrapper from "../../inputsWrapper/inputsWrapper";
import SettingsButtons from "../../buttons/SettingButtons";
import { adjustPropertyInList } from "../../../helpers/utils";
import {
    lensProp,
    set,
    pipe,
    unnest,
    groupBy,
    propOr,
    dissoc,
    identity,
    mergeAll,
    reduce,
    pathEq,
    reduced,
    values,
    remove,
    map,
    view,
    mapObjIndexed
} from 'ramda';
import { useAxios } from "../../../hooks/useaxios";
import { useIsMounted } from "../../../hooks/useIsMounted";
import DeleteButton from "../../ui/deletebutton/deletebutton";
import toastContext from "../../stores/toast/toastcontext";


export const SettingsHeadline = {
    LEADING: 'settings_headline_leading',
    EVERY: 'settings_headline_every',
}

const FieldGroup = React.forwardRef(({ items, headlineType, rowIndex }, ref) => {
    return items.map((props, i) => (
        <InputsWrapper ref={el => ref[i] = el} key={`${i}${props.prop}`} {...props} headlineType={headlineType} rowIndex={rowIndex} />
    ))
});


const toKeyValuePair = target => ({ [target?.name]: target?.value })

const toRowPairs = map(toKeyValuePair)

const prepRequest = pipe(values, unnest, map(toRowPairs), map(mergeAll))

const DynamicSettings = ({ siteId, gamSiteId, rowStructure, controls, settings }) => {


    const [rowsRaw, setRowsRaw] = useState(settings.inputs === "multiple" ? settings.groupBy ? {} : [] : rowStructure)
    const [displayErrors, setDisplayErrors] = useState(false);
    const [updatedRow, setUpdatedRow] = useState([]);

    const refs = useRef({});
    const { data, setRequest } = useAxios();
    const isMounted = useIsMounted();
    const ToastContext = useContext(toastContext);

    useEffect(() => {
        if (data?.length > 0 && settings.inputs === "single") {
            const modifiedRaw = JSON.parse(JSON.stringify(...rowsRaw))
            modifiedRaw.value = data[0]?.[settings.inputValue]
            setUpdatedRow([modifiedRaw])
        }
        else {
            setUpdatedRow(rowsRaw)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const reqParams = (url, method, data = {}, completeAction = null, onComplete = null) => ({
        url,
        method,
        data,
        completeAction,
        onComplete,
        parseResponse: pipe(propOr(identity, 'data'))
    })

    useEffect(() => {
        if (isMounted.current) {
            const options = reqParams(`/${settings.endpoint}/${gamSiteId}`, 'GET');
            setRequest(options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    useEffect(() => {
        if (data && data.length > 0) {
            addRows(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    /**
     * Verifies multiple input fields by checking the data attribute data-valid
     * @param inputFields
     * @return {boolean}
     */
    const areValidInputs = inputFields => reduce((acc, item) => {
            return pathEq(['attributes', 'data-valid', 'nodeValue'], 'false', item) ? reduced(false) : true
        },
        true,
        inputFields)

    /**
     * Validate a row with input fields
     * @param fields {array} list with input fields
     * @return {*}
     */
    const areValidFields = fields => reduce((acc, item) => areValidInputs(item), true, fields)

    /**
     * Validates one or more sections
     * @param sections {array} i.e. [ section1, section2 ]
     * @return {boolean}
     */
    const areValidSections = sections => reduce((acc, section) => areValidFields(section) ? true : reduced(false), true, sections)


    /**
     * Trim empty/orphaned sections
     * @param sections {object}
     * @return {object}
     */
    const trimSections = sections => mapObjIndexed(val => val.filter(input => !input.includes(null)), sections);
    const showToastIfMsg = (success = true, params = []) => {
        let message = ''
        if (settings.toastMessage) {
            const msgObj = success ? (settings.toastMessage.success || settings.toastMessage) : settings.toastMessage.error

            if (typeof msgObj === 'function') {
                message = msgObj(...params);
            } else {
                message = msgObj || '';
            }    
        }
        if (message !== '') {
            ToastContext.display(message, !success);
        } else {
            console.warn(`No ${success ? 'success' : 'error'}-message provided to dynamicSettings`, params)
        }
    }

    const onSubmit = async (e) => {
        setDisplayErrors(false);

        e.preventDefault();
        e.stopPropagation();

        const filtered = trimSections(refs.current);
        const validInput = areValidSections(values(filtered))

        if (validInput) {
            const parsed = mapObjIndexed(val => extractFields(val), filtered)
            let prepped = prepRequest(parsed)
            
            if (settings.prepRequest) {
                prepped = settings.prepRequest(prepped, { siteSiteId: siteId })
            }

            if (!settings.validateFunc || await settings.validateFunc(prepped)) {

                const options = reqParams(
                    `/${settings.endpoint}/${gamSiteId}`,
                    'POST', 
                    prepped,
                    null,
                    () => showToastIfMsg(true, [prepped])
                );
                setRequest(options);
            } else{
                setDisplayErrors(true);
                showToastIfMsg(false, [prepped])
            }
        }
        else {
            setDisplayErrors(true);
        }
    }

    const extractField = field => field.map(field => ({ name: field?.name, value: field?.value }))

    const extractFields = fields => fields.map(extractField);

    const parseFields = pipe(Object.values, map(extractFields));

    /**
     * Update the value prop from updates to source
     * @param source
     * @param updates
     * @return {array}
     */
    const updateVal = (source, updates) => {
        return source.map((item, i) => {
            return { ...item, value: updates[i].value }
        })
    }

    /**
     * Synchronize grouped settings
     * @param source
     * @param updated
     * @return {array}
     */
    const syncGrouped = (source, updated) => {
        const parsed = mapObjIndexed(val => extractFields(val), updated)
        const syncedList = mapObjIndexed((rowsByType, key) => {
            return rowsByType.map((item, i) => {
                return updateVal(item, parsed[key][i])
            })
        }, source)
        return syncedList;
    }

    /**
     * Synchronize none grouped items
     * @param source
     * @param updated
     * @return {*}
     */
    const sync = (source, updated) => {
        const parsed = parseFields(updated);
        return source.map((item, i) => {
            return { ...item, value: parsed[i].value }
        })
    }

    const newRowType = type => adjustPropertyInList(rowStructure, 'value', item => item.prop && item.prop === 'type', type)

    const addGroupedRow = (type, list, row) => {
        const lens = lensProp(type);
        const old = view(lens, list) ?? [];
        return set(lens, [...old, row], list)
    }

    // TODO: Add remove for none grouped
    const removeRow = (type, index) => {
        if (settings.groupBy) {
            deleteFromGroupedRow(type, index, rowsRaw)
        }
    }

    const deleteFromGroupedRow = (group, index, list) => {
        const lens = lensProp(group);
        const grouped = view(lens, list);
        const updatedGrouped = remove(index, 1, grouped);

        // TODO: This is not really needed, remove!
        if (updatedGrouped.length === 0) {
            const rr = dissoc(group, list)
            setRowsRaw(rr)
            refs.current = {};
        }
        else {
            const r = set(lens, updatedGrouped, list);
            setRowsRaw(r)
        }
    }


    /**
     * Add multiple rows at once
     * @param items
     */
    const addRows = (items) => {
        if (settings.inputs === "multiple") {
            const rows = items.map((item) => newRowType(item.type));
            const newRows = rows.map((row, i) => {
                const source = items[i];
                return row?.map((fields) => ({
                    ...fields,
                    value: source[fields.prop],
                }));
            });

            if (settings.groupBy) {
                const grouped = groupBy(settings.groupBy, newRows);
                setRowsRaw(grouped);
            }
        }
    };

    const allowAddForType = type => {
        const target = rowsRaw[type] ?? rowsRaw;
        const requirements = settings && settings.limit && rowsRaw && rowsRaw[type];
        return requirements ? target.length <= (settings.limit - 1) : true;
    }

    const addRow = (type = '') => {
        if (allowAddForType(type)) {
            const row = newRowType(type);
            if (!row) {
                throw Error('Unable to create a new row');
            }

            if (settings.groupBy) {
                const synced = syncGrouped(rowsRaw, refs.current);
                const updatedRows = addGroupedRow(type, synced, row);
                setRowsRaw(updatedRows);
            }
            else {
                const synced = sync(rowsRaw, refs.current);
                let updatedRows = [...synced, row]
                setRowsRaw(updatedRows);
            }
        }
        else {
            ToastContext.display(`Limit reach for group ${type} (${settings.limit})`)
        }
    }

    /**
     * Register a new setting for for a type {string} and row number {integer}
     * @param type {string}
     * @param index {integer}
     * @return {object} a new row ref object
     */
    const registerRow = (type, index) => {
        refs.current[type] = refs.current[type] ?? [];
        refs.current[type][index] = refs.current[type][index] ?? [];

        return refs.current[type][index]
    }

    /**
     * Rows component
     * @param items {array} List with inputs
     */
    const Rows = ({ items, type = 'inputs' }) => items.map((row, index) => (
        <Row key={`row-${type}${index}`} className={`${styles.setting_row} ${styles[settings.headlineType]}`}>
            <FieldGroup ref={registerRow(type, index)} key={`row-setting-${type}${index}`} headlineType={settings.headlineType} rowIndex={index} items={row} />
            {
                settings.inputs === "multiple" && <DeleteButton onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    removeRow(type, index)
                }} />
            }
        </Row>
    ))

    return (
        <Container fluid className={styles.modular_settings_wrapper}>
            { <p>{settings.description}</p> }
            <Form className={displayErrors ? styles.setting_invalid : ''} onSubmit={onSubmit}>
                {
                    settings.inputs === "multiple" && settings.groupBy && Object.keys(rowsRaw).map((group, i) =>
                        <div key={`group-${group}-${i}`}>
                            <h5>{group}</h5>
                            <Rows items={rowsRaw[group]} type={group} />

                        </div>
                    )
                }
                {settings.inputs === "multiple" && !settings.groupBy && <Rows items={rowsRaw} />}
                {settings.inputs === "single" && <Rows items={[updatedRow]} />}
                <div>
                    <SettingsButtons onClick={addRow} {...controls} />
                </div>
            </Form>
        </Container>
    );
}


export default DynamicSettings;
