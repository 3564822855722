import styles from "./siteCache.module.scss";

import React, { useMemo, useState} from 'react';
import {useAxios} from "../../../hooks/useaxios";
import Button from "react-bootstrap/Button";
import { isOcelotSite } from "../../../helpers/sites";

const SiteCache = ({gamSiteId}) => {
 
    const isCacheAvailable = isOcelotSite(gamSiteId);

    const { loading, setRequest } = useAxios();

    const [responseMessages, setResponseMessages ] = useState([]);
    const [responseTitle, setResponseTitle ] = useState('');

    const allowSubmit = useMemo(() => ( !loading), [loading]);

    const handleComplete = (response, parsedData) => {

        if(response.status === 200){

            const urls = response.data?.data?.invalidatedPaths;

            if(urls && Array.isArray(urls) && urls.length > 0 ) {
                setResponseMessages(urls);
                setResponseTitle(`Following urls / articleIds (most read) for ${gamSiteId} have been invalidated:`);
            } else {
                setResponseTitle(`No pages invalidated`);
            }         
        } else {
            setResponseTitle(`No pages invalidated. Something went wrong.`);
        }
}

    const submit = () => {

        setResponseMessages([]);

        setRequest({
            method: 'POST',
            url: `cache`,
            data: { gamSiteId },
            onComplete: handleComplete
        });
    }

    const makeResponseList = (responseMessages) => {

        return <div>
            <ul>
                {
                    Object.keys(responseMessages).map( (i) => {
                        const msg = responseMessages[i];
                        return msg && <li key={i}>{msg}</li>        
                    })
                }        
            </ul>        
        </div>        
    };

    return (
        <div className={styles.cache_container}>
    
            { !isCacheAvailable && <p> Not available for this site</p>}

            { isCacheAvailable && 
                <>
                    <p> Here you clear cache for latest articles and frontpages for this site.<br/>
                        For clearing cache for all site pages, please contact developer.<br/>                        
                    </p>
        
                    <h6>{responseTitle}</h6>        

                    { responseMessages.length > 0 && makeResponseList(responseMessages) }            
        
                    <div className={styles.cache_button_wrapper}>
        
                        <Button onClick={submit} disabled={!allowSubmit} type="submit" className="btn btn-primary">
                            {`Clear Cache for ${gamSiteId}`} 
                        </Button>
                    </div>
                </>
            }
        </div>
    )
}

export default SiteCache;