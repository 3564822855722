const isValid = req => req && req.hasOwnProperty('method') && req.hasOwnProperty('url');

const config = {
    headers: {
        "X-CSRFTOKEN": 'csrftoken'
    }
};

export const requests = {
    isValid,
    config
}