import React from "react"
import styles from "./alert.module.scss"
import SvgExclamation from "../svg/svgexclamation";


const ErrorAlert = props =>  (
    <div className={`alert_danger ${props.className}`}>
        {props.children}
        <div className={styles.alert_danger__wrapper}>
            <span>{props.message}</span>
        </div>
    </div>
)

const ExclamationPoint = () => (  <SvgExclamation/>)

const PointyLeft = () => (<div className={'alert_left'}></div>)



const withLeftPoint = BaseComponent => props => (
  <BaseComponent {...props}>
    <PointyLeft />
  </BaseComponent>
)

const withExclamation = BaseComponent => props => (
    <>
        { props.predicate &&
        <BaseComponent className={styles.alert_danger__exclamation} {...props}>
        <ExclamationPoint />
    </BaseComponent> }
    </>
)

export const AlertErrorPointyLeft = withLeftPoint(ErrorAlert)
export const AlertErrorExclamation = withExclamation(ErrorAlert)


