import {useContext, useReducer, useEffect, useMemo} from 'react'
import instance from '../../../axios'
import authContext from "../auth/authcontext";
import NetworkReducer from "./networkReducer"
import NetworkContext from './networkContext'
import {ON_ERROR} from "./types";
import {propOr} from 'ramda';
import React from "react";

const Network = (props) => {
    let initialState = {error: undefined}
    const AuthContext = useContext(authContext);
    const [state, dispatch] = useReducer(NetworkReducer, initialState);

    useEffect(() => {
        /*instance.interceptors.request.use(
            config => {
                if (AuthContext.token) {
                    config.headers['Authorization'] = `Bearer ${AuthContext.token}`;
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });*/

        instance.interceptors.response.use(response => response, error => {

            if (window.location.pathname !== 'login' && error.response && error.response.status === 401 ) {
                AuthContext.logout()            
            }

            if (error.response && error.response.data) {

                dispatch({type: ON_ERROR, payload: error.response.data})
            }
            Promise.reject(error)
        })
    }, [AuthContext])

    useMemo(() => {
        instance.interceptors.request.use(
            config => {
                if (AuthContext.token) {
                    config.headers['Authorization'] = `Bearer ${AuthContext.token}`;
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
    }, [AuthContext.token])

    const errorDesc = propOr('', 'message', state.error);

    return (
        <NetworkContext.Provider value={{error: state.error, errorDesc}}>
            {props.children}
        </NetworkContext.Provider>
    );
}

export default Network


