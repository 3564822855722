import {includes, pipe, reduce} from "ramda";

const isPlacementName = val => /(?!^\d+$)^[\w-]{3,}$/gi.test(val);

export const validInputRefs = refs =>  pipe(
        reduce((acc, item) => {
            acc.push(item.validity.valid)
            return acc
        }, []),
        includes(false)
    )(refs)


export const validate = {
    isPlacementName
}




