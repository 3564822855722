import React, {useReducer, useEffect, useCallback} from "react";
import BundleVersionReducer from "./bundleVersionReducer";
import {useAxios} from "../../../hooks/useaxios";
import {VERSION_FETCH_COMPLETE, VERSION_FETCH_BY_GAMSITE_ID } from "./types";
import {
    is
} from "ramda";
import {useIsLoggedIn} from "../../../hooks/useloginstate";
import BundleVersionContext from "./bundleVersionContext";

const initialState = {
    bundleVersion: [],
    sitesConfig: {}
};

const BundleVersionContextModel = (props) => {
    const [state, dispatch] = useReducer(BundleVersionReducer, initialState);
    const { setRequest } = useAxios(dispatch);

    const fetch = useCallback(async () => setRequest({ method: 'GET', url: 'adsBundleVersions', completeAction: VERSION_FETCH_COMPLETE }), [setRequest]);
    const fetchadsBundleVersionsByGamSiteId = gamSiteId => {
        if (is(String, gamSiteId) === true ) {
            setRequest({ method: 'GET', url: `adsBundleVersions/${gamSiteId}`, completeAction: VERSION_FETCH_BY_GAMSITE_ID });
        }
    }

    const {loggedIn} = useIsLoggedIn();

    useEffect(()=> {
        if (loggedIn) {
            fetch();
        }
    },[loggedIn]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <BundleVersionContext.Provider value={{
            bundleVersion: state.bundleVersion,
            dispatch,
            sitesConfig: state.sitesConfig,
            fetchadsBundleVersionsByGamSiteId: fetchadsBundleVersionsByGamSiteId
        }}>
            {props.children}
        </BundleVersionContext.Provider>
    );
};

export default BundleVersionContextModel;