import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from "./collapsebutton.module.scss";
import SvgCollapse from "../../svg/svgcollapse";

const CollapseButton = props => {
    const btnRef = React.useRef(null);
    const { variant, theme, onToggle, collapsed, disabled, autoScrollTop } = props;

    const variantStyle = `collapsebutton_${variant}`;
    const themeStyle = `collapsebutton_${theme}`;

    const scrollToView = React.useCallback(() => {
        setTimeout(() => btnRef.current && btnRef.current.scrollIntoView(), 300);
    }, []);

    const onClick = useCallback((e) => {
        if (autoScrollTop) {
            scrollToView()
        }
        e.preventDefault();
        e.stopPropagation();
        onToggle(!collapsed)
    }, [collapsed, autoScrollTop, scrollToView, onToggle]);

    return(
        <button
            ref={btnRef}
            onClick={onClick}
            disabled={disabled}
            className={`${styles.collapsebutton} ${styles[themeStyle]} ${styles[variantStyle]} ${!collapsed ? styles.collapsebutton_open : styles.collapsebutton_closed}`}>
            <SvgCollapse/>
        </button>
    )
}

CollapseButton.propTypes = {
    onToggle: PropTypes.func.isRequired,
    variant: PropTypes.string,
    theme: PropTypes.string
};

CollapseButton.defaultProps = {
    variant: 'regular',
    theme: 'default'
}

export default CollapseButton