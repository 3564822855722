import instance from "../axios";
import { useEffect, useState } from "react";
import {
    last,
    pipe,
    is,
    isEmpty,
    ifElse,
    has,
    prop,
    mergeDeepLeft,
    always,
    propOr,
    objOf
} from 'ramda';
import {isNotNil} from "../helpers/utils";


const parseResponse = pipe(
    propOr(undefined, 'data'),
    objOf('response'));

const makePayload = (request, response) => {
    const payload = parseResponse(response);
    return ifElse(
        has('completePayload'),
        pipe(prop('completePayload'), mergeDeepLeft(payload)),
        always(payload)
    )(request);
};


const handleComplete = (dispatch, request, response)=> {
    if (is(Function, dispatch)) {
        const payload = makePayload(request, response);
        dispatch({ type: request.completeAction, payload});
    }
}


export const useAxios = (dispatch) => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [requests, setRequests] = useState([]);

    const setRequest = reqParams => {

        if (isNotNil(reqParams)) {
            setTimeout(() => {
                const r = [ ...requests, reqParams ];
                setRequests(r);
            })
        }
    }

    useEffect(() => {

        (async () => {

            if (isEmpty(requests) || !is(Array, requests)) {
                return;
            }

            const request = last(requests);
            const { parseResponse, onComplete, completeAction, ...params} = request;

            setLoading(true);
            try {
                const response = await instance(params);
                const { data } = response;
                const parsedData = is(Function, parseResponse) ? parseResponse(data) : data;

                if (isNotNil(completeAction)){

                    handleComplete(dispatch, request, response);
                }

                if (is(Function, onComplete)) {
                    onComplete(response, parsedData);
                }

                setData(parsedData);

            } catch (e) {
                setError(e);
            }
            setLoading(false);
        })();
    }, [requests, dispatch]);

    return { data, error, loading, setRequest };
};
