import React, {useContext, useState, useEffect, useMemo} from "react";
import authContext from "../stores/auth/authcontext";
import styles from "./timeout.module.scss";



function Timeout(props) {

    const AuthContext = useContext(authContext);

    const [expireStamp, setExpireStamp] = useState(0);

    const [minutesRemaining, setMinutesRemaining] = useState(0);

    const [remainingTimeLabel, setRemainingTimeLabel] = useState('')

    const [timer, setTimer] = useState(null);

    const formattedTime = useMemo(() => {

            const min = minutesRemaining && minutesRemaining > 0 ? minutesRemaining : 0
            const numbers = minutesRemaining < 10 ? `0${minutesRemaining}` : minutesRemaining

            const label = min > 0 ?
                `Session ends in 0:${numbers}` :
                'Session ends soon, please login again'
            return label;
        }
    ,[minutesRemaining])

    const initTimer = () => {

        const timerId = setInterval(updateStamp, 1000)
        setTimer(timerId)
    }

    const clearTimer = (timerId) => {

        if (timerId) {

            clearInterval(timerId)
            setTimer(null)
        }
    }

    useEffect(() => {

        // console.log("useEffect minutesRemaining ", timer);

        if (minutesRemaining <= 0) {
            clearTimer(timer)
        }
        setRemainingTimeLabel(formattedTime)

    },[minutesRemaining]); // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        // console.log(" AuthContext useEffect ");

        setExpireStamp(AuthContext.stamp);
    },[AuthContext]);

    useEffect(() => {

        if (expireStamp && expireStamp !== '' && expireStamp > 0) {
            initTimer()
        }
        return () => {
            // console.log("useEffect CLEANUP loginStamp");
            clearTimer(timer)
        }
    },[expireStamp]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateStamp = () => {

        const min = getMinutesFromMillisec()        

        setMinutesRemaining(min);
    }

    const getUnixDiff = () => {

        let millisec = 0;
        if (expireStamp > 0) {
            millisec = expireStamp - Date.now();
        }
        return millisec;
    }

    const getMinutesFromMillisec = () => {

        const millisec = getUnixDiff()
        // const seconds = Math.floor(millisec / (1000) );
        const minutes = Math.floor(millisec / (1000 * 60) );        
        return minutes;
    }


    return (
        <div className={styles.timeout_wrapper}>
            <p>
                {remainingTimeLabel}
            </p>
        </div>
    )
};


export default Timeout

