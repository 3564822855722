import * as React from "react";

export const SvgUser = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M97.48 89.957c-2.812-6.323-16.537-10.507-25.656-14.435-9.119-3.928-7.873-6.31-8.201-9.567a24.47 24.47 0 01-.104-1.778c2.774-2.804 4.948-6.626 6.496-10.621 0 0 .087-.217.112-.283.349-.928.66-1.896.926-2.883 2.09-.412 3.308-2.632 3.774-4.684.47-.831 1.447-2.878 1.223-5.201-.226-2.337-1.031-3.69-2.035-4.446.146-1.073.229-2.032.229-2.855 0-4.127 1.654-16.804-2.832-21.47-2.378-2.474-4.656-5.048-7.876-6.947l.35-1.398-3.477-.088c-.09-.034-.184-.064-.275-.096l.461-1.751-3.099.966.968-2.42-4.755 1.938a32.775 32.775 0 00-1.675-.073c-6.945-.115-12.931 2.522-17.193 6.259-1.473.713-4.062 1.943-4.062 1.943l.973 1.328-.178.232-3.603 1.441 1.997 1.18c-.045.096-.089.192-.132.29l-3.123 2.499 2.316-.331c-2.183 6.904-2.316 16.975-2.316 17.852 0 .127.04.484.112 1.013-1.373.588-2.59 1.97-2.875 4.933-.224 2.323.753 4.371 1.223 5.201.473 2.088 1.726 4.349 3.883 4.704a31.408 31.408 0 001.007 3.082l.032.081c1.582 4.074 3.817 7.97 6.674 10.785a23.082 23.082 0 01-.099 1.597c-.328 3.257.631 5.639-8.488 9.567-9.119 3.929-22.844 8.113-25.656 14.436C-.287 96.28.837 100 .837 100h98.321c.001 0 1.134-3.72-1.678-10.043z"
        fill='#999'
        fillRule="nonzero"
      />
    </svg>
  )
}