import React from "react";
import {map} from "ramda";
import styles from "../adslot/adslot.module.scss";
import {sizes, transformSizeObject_toTypeahead} from "../../helpers/sizes";
import {Typeahead, Token} from "react-bootstrap-typeahead";

function sortCurrentSelected(selections) {
    const sortedSelection = selections.map(selection => ({
        priority: selection.isGlobal ? 1
            : selection?.size?.adSlotSize?.startsWith("r") ? 2
            : 0,
        selection,
    })).sort(
        (a, b) => a.priority - b.priority
    ).map(
        item => item.selection
    );
    return sortedSelection;
}

const SizeSelector = props => {
    const { selected = [], options = [], onChange, onBlur, isEditable } = props;
    const sortedSelected = React.useMemo(() => sortCurrentSelected(selected), [selected]);

    const handleChange = updatedSelected => {
        const updatedSelectedGlobalRemoved = updatedSelected.map(
            s => typeof s === "object" && s.isGlobal ? undefined : s
        ).filter(
            s => s !== undefined
        );
        const mappedSizes = map(
            transformSizeObject_toTypeahead, 
            updatedSelectedGlobalRemoved.map(s => typeof s === 'object' && "size" in s ? s.size : s),
        );
        onChange(mappedSizes);
    }

    const typeAheadOptions = React.useMemo(() => {
        let deletedOptions = selected
            .map(sel => sel?.size?.adSlotSize)
            .filter(item => !!item && item.startsWith("r"));
        
        deletedOptions = deletedOptions.concat(
            deletedOptions.map(opt => opt.slice(1))
        );
        return options.filter(opt => !deletedOptions.includes(opt));
    }, [options, selected])


    const tokenRenderer = React.useCallback((option, _, index) => {
        const handleRemove = (option) => {

            const isGlobalRemove = selected.some(
                select => select.size?.adSlotSize === option?.adSlotSize && select.isGlobal
            );
            const upd = isGlobalRemove ? [option?.adSlotSize?.startsWith("r") ? option?.adSlotSize.slice(1) : "r" + option?.adSlotSize] : [];
                
            handleChange(
                selected.filter(
                    opt => opt?.size?.adSlotSize !== option?.adSlotSize
                ).concat(upd)
            );                
        }

        if (!option) return null;
        const isGlobalDelete = !!option.size?.adSlotSize?.startsWith("r");

        return (
            <Token
                key={index}
                className={isGlobalDelete ? styles.slot_option_global_delete
                    : option.isGlobal ? styles.slot_option_global
                    : undefined}
                onRemove={ handleRemove }
                disabled={!isEditable}
                option={option.size}>
                {option.size?.adSlotSize}
            </Token>
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Typeahead
            classNames={styles.slot_option}
            id={'format'}
            placeholder={'Click to add a size'}
            newSelectionPrefix={'Add size: '}
            renderToken={tokenRenderer}
            multiple={true}
            allowNew={sizes.allowNewSize}
            onBlur={onBlur}
            disabled={!isEditable}
            selected={sortedSelected}
            options={typeAheadOptions}
            onChange={handleChange}
        />
    )
}
const comparator = (prevValue, newValue) => prevValue.options === newValue.options;
export default React.memo(SizeSelector, comparator);