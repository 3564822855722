import React  from "react";
import {Button} from "react-bootstrap";
import styles from "./settingsbuttons.module.scss"

const OptionalButtons = ({onClick, items}) => items.map( ({onClickParams, label}, i) => {
	return <Button onClick={ () => onClick.apply(null, onClickParams)} key={`settingButton${i}`}>
		{label}
	</Button>
});

const SettingsButtons =({onClick = () => {}, submit, options, btn}) => {
	return 	<div className={styles.button_wrapper}>
		{options && Array.isArray(options) && <OptionalButtons onClick={onClick} items={options}/>}

		{btn && <Button onClick={btn.onClick}>{btn.label}</Button>}

		{
			submit && <Button type='submit'>{submit?.label}</Button>
		}

	</div>
}

export default SettingsButtons;

