import React from 'react';
import styles from "./deletebutton.module.scss";
import SvgGarbage from "../../svg/svggarbage";

const DeleteButton = props => (
    <button className={styles["delete-button"]} {...props}>
        <SvgGarbage/>
    </button>
)

export default DeleteButton;