import {propOr, pipe, prop, mergeAll, dissoc, last, pathOr} from "ramda";
import {ROLLBACK_SETTINGS} from "../components/stores/sites/types";


export const selectPayloadFromAction = propOr({}, 'payload');

export const selectSettingsFromAction = pipe(selectPayloadFromAction, prop('settings'));

/**
 * Return an object as {name, type, settings} from an update action
 * @param action
 */
export const unwrapAction = action => mergeAll(
    [
        selectPayloadFromAction(action),
        dissoc('payload', action)
]);




export const rollbackAction = (siteName, settings) => ({ type: ROLLBACK_SETTINGS, payload: {siteName, settings} });

export const makeRollbackAction = (siteHistory, prevSettings) => {

    const { name } = prevSettings;

    const rollbackState = last(siteHistory) || prevSettings;
    const settingsUnwrapped = pathOr(rollbackState, ['payload', 'settings'], rollbackState);

    return rollbackAction(name, settingsUnwrapped);
}