import {PAGEVERTICALS_FETCH_COMPLETE, VERTICALS_FETCH_COMPLETE, VERTICALS_UPDATED, PAGEVERTICALS_UPDATED} from "./types";
import {
    pluck,
    clone,
    pathOr,
    propOr,
    is,
    gt,
    head,
    both,
    __,
    always,
    ifElse,
    pipe,

} from "ramda";
import {isNotNil} from "../../../helpers/utils";

const arrayHasItems = pipe(propOr(0, 'length'), gt(__, 0));

const dataFromResponse = pipe(pathOr(undefined, ['response', 'data']), clone);

const expectedResponse = both( is(Array), arrayHasItems);

const getFirstGamSiteId = pipe(pluck('gamSiteId'), head);

const pickGamSiteFromResponse = ifElse(expectedResponse, getFirstGamSiteId, always(undefined));


export default (state, action) => {
    const { payload, type } = action;

    switch (type) {

        case VERTICALS_FETCH_COMPLETE:
        case VERTICALS_UPDATED:
            
            return { ...state, verticals: [ ...action.payload.response.data ]};

        case PAGEVERTICALS_UPDATED:
        case PAGEVERTICALS_FETCH_COMPLETE:

            const data = dataFromResponse(payload);
            const gamSiteId = pickGamSiteFromResponse(data);
        
            const r = both(isNotNil(gamSiteId), is(String, gamSiteId)) ?
                { ...state, pageVerticals: { ...state.pageVerticals, [gamSiteId]: payload.response.data }} :
                state;

            return r;

        default:
            return state;
    }
};
