import React, {useContext, useState} from "react";
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from "./login.module.scss";
import {Redirect} from "react-router-dom";
import Button from "react-bootstrap/Button";
import authContext from "../stores/auth/authcontext";
import networkContext from "../stores/network/networkContext";
import {AlertErrorExclamation} from "../alert/errorAlert";
import {useIsLoggedIn} from "../../hooks/useloginstate";

function Login(props) {

    const AuthContext = useContext(authContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {loggedIn} = useIsLoggedIn();

    const NetworkContext = useContext(networkContext);
    const hasError = NetworkContext.error;

    const onUserNameChange = (e) => setUsername(e.target.value);
    const onPasswordChange = e => setPassword(e.target.value);

    const onSubmit = (e) => {
        AuthContext.login(username, password);
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <>
            {loggedIn && <Redirect to='/' />}

            {!loggedIn &&
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <AlertErrorExclamation predicate={hasError} message={NetworkContext.errorDesc} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <div className={styles.login_wrapper}>
                                <Form onSubmit={onSubmit}>
                                    <FormGroup>
                                        <Form.Label>Username</Form.Label>
                                            <FormControl type="text" value={username} onChange={onUserNameChange} autocomplete={'username'}/>
                                                <Form.Label>Password</Form.Label>
                                            <FormControl type="password" value={password} onChange={onPasswordChange} autocomplete={'current-password'}/>
                                            <FormControl.Feedback />
                                                <div className={styles.submit_wrapper}>
                                                    <Button type="submit" variant="aller">login</Button>
                                                </div>
                                    </FormGroup>
                                </Form>
                            </div> 
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
};

export default Login
