import Collapse from 'react-bootstrap/Collapse'
import {useState} from "react";
import * as React from 'react';
import styles from './collapsable.module.scss'
import CollapseButton from "../ui/collapsebutton/collapsebutton";
import {useLocalStorage} from "../../hooks/uselocalstorage";
import PropTypes from "prop-types";

const Collapsable = React.memo(props => {

	const collapsedTitle = props.title.replace(' ', '_');
	const [collapsedState, setCollapsedState] = useLocalStorage(collapsedTitle)
	const [open, setOpen] = useState(collapsedState);

	return (
	<>
		<div className={styles.collapse_head}>
			<h5>{props.title}</h5>
			<CollapseButton collapsed={open}  variant="small" theme="dark" onToggle={() => {
				setCollapsedState(!open)
				setOpen(!open)
			}} aria-expanded={open}></CollapseButton>
		</div>

		<div className={styles.collapse_body}>
		<Collapse in={open}>
			<div>
				{open && props.children}
			</div>
		</Collapse>
		</div>
	</>
	)
})

Collapsable.propTypes = {
	title: PropTypes.string
};

Collapsable.defaultProps = {
	title: 'Some settings',
}

export default Collapsable