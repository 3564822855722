import {useContext, useRef, useEffect, useState} from "react";
import placementContext from "../stores/placement/placementscontext";
import { is, identity, memoizeWith, pipe, test} from "ramda";
import {typeahead} from "../../helpers/typeahead";
import {
    findPlacementNameById,
    isNotPlacementByName,
    placementIdByName,
} from "../../helpers/placements";
import {Typeahead} from "react-bootstrap-typeahead";
import styles from "./adformat.module.scss";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React from "react";
import {isNotNil} from "../../helpers/utils";

const onlyLetters = test(/^[a-zA-Z0-9_-]+$/);
const onlyDigits = test(/^([0-9])/); 

const isNotEmptyArray = val => is(Array) && val.length > 0;
const renderTooltip = props => <Tooltip>This is a global format</Tooltip>

const GlobalElement = props => {
    return (
        <OverlayTrigger delay={{ show: 350, hide: 0 }} placement="right" overlay={renderTooltip()}>
            {({ ref, ...triggerHandler }) => (
                <div ref={ref} {...triggerHandler} className={styles.global_marker}>
                    {props.children}
                </div>
            )}
        </OverlayTrigger>
    );
};

const AdFormat = props => {
    const { onChange, selected, options, isGlobal } = props;
    const PlacementsContext = useContext(placementContext);
    const [localVal, setLocalVal] = useState([selected]);
    const ref = useRef();

    useEffect( () => {
        setLocalVal([selected])
    }, [selected]);

    const resolvePlacementID = placements => pipe(typeahead.toValue, placementIdByName(placements));  // TODO: Refactor placementIdByName a pointfree version

    const _onChange = memoizeWith(identity, val => {
        const placementId = resolvePlacementID(PlacementsContext.placements)(val);
        const formattedId = typeahead.toTypeahead(placementId);
        if (isNotNil(val) && isNotEmptyArray(val)) {
            if ( onlyLetters(placementId)) {
                onChange(placementId)
            }
        }
        setLocalVal(formattedId)
    });

    const resolveName= memoizeWith( (...args) => args[0], pipe(findPlacementNameById, typeahead.toTypeahead) );

    const allowNew = (result, value) => !onlyDigits(value.text) && isNotPlacementByName(value.text)(PlacementsContext.placements);

    const FormatSelector =
        <Typeahead classNames={styles.slot_option}
            ref={ref}
            onChange={_onChange}
            selected={resolveName( typeahead.toInt(localVal), PlacementsContext.placements)}
            options={options || []}
            id={'format'}
            placeholder={'Click to add new format!'}
            newSelectionPrefix={'New format: '}
            allowNew={allowNew}
        />

    return (
        <>
            { isGlobal ? <GlobalElement>{FormatSelector}</GlobalElement> : FormatSelector }
        </>
    )
}

export default AdFormat;