import {path} from 'ramda'

/**
 * Merges the properties of two objects, prioritizing values from the second object.
 *
 * @param {Object} obj1 - The first object to be merged.
 * @param {Object} obj2 - The second object whose properties will be merged into the first object.
 * @returns {Object} - A new object containing properties from both input objects.
 *
 * @example
 * const object1 = { a: 1, b: 2 };
 * const object2 = { a: '', b: 3 };
 * const mergedObject = mergeObjects(object1, object2);
 * // Result: { a: 1, b: 3 }
 */
function mergeObjects(obj1, obj2) {
  const merged = { ...obj1 };

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (obj2[key] !== undefined && obj2[key] !== null && obj2[key] !== '') {
        merged[key] = obj2[key];
      }
    }
  }

  return merged;
}


/** 
* Merged current site's placement with Global placement
* @param currentSource
* @param globalSource
* @param allSource
* @returns {arrays} merged FrontPageAds
*/
export const mergeFrontPageAdsGlobalWithCurrentSource = (currentSource, globalSource, allSource) => {
  let mergedObject;
  const isGlobalSection = currentSource.isGlobal;
  const currentGamSiteId = currentSource.gamSiteId;
  const currentSourceFPA = path(['frontPageAd'], currentSource);
  if (isGlobalSection  === "true") return currentSourceFPA;
  const globalFPA = path(['frontPageAd'], globalSource);
  
  if (currentSource.parentSiteId === 0) {
      return mergedObject = mergeObjects(globalFPA, currentSourceFPA);
  } else {
      const findByGamSiteId = site => site?.gamSiteId !== currentGamSiteId && !!currentGamSiteId?.endsWith(site.gamSiteId);
      const parentFPA = allSource.find(findByGamSiteId)?.frontPageAd;
      mergedObject = mergeObjects(globalFPA, parentFPA);
      return mergedObject = mergeObjects(mergedObject, currentSourceFPA);
  }
}